import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const DeleteSliderOrd = () => {
  const [selectedCardId, setSelectedCardId] = useState('');
  const [cards, setCards] = useState([]);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    axios
      .get(`${baseURL}/api/ord-slider-cards`)
      .then((response) => setCards(response.data))
      .catch((error) => console.error('Помилка при завантаженні карток:', error));
  }, []);

  const handleDelete = () => {
    axios
      .delete(`${baseURL}/api/ord-slider-cards/${selectedCardId}`)
      .then((response) => {
        setCards(cards.filter((card) => card._id !== selectedCardId));
        setSelectedCardId('');
        showNotification('Картку видалено успішно', 'success');
      })
      .catch((error) => {
        console.error('Помилка при видаленні картки:', error);
        showNotification('Не вдалося видалити картку.', 'error');
      });
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className="delete_slide">
      <p className="block_title">Виберіть картку для видалення</p>
      <select value={selectedCardId} onChange={(e) => setSelectedCardId(e.target.value)}>
        <option value="">Виберіть картку для видалення</option>
        {cards.map((card) => (
          <option key={card._id} value={card._id}>
            {card.productName}
          </option>
        ))}
      </select>
      <button
        className="delete_slide-btn"
        onClick={handleDelete}
        disabled={!selectedCardId}
      >
        Видалити картку
      </button>

      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default DeleteSliderOrd;