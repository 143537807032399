import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useCart } from '../../CartContext';
import NavPanel from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer'
import iconSlider from '../../icon/other-icon/other-icon';
import '../catalog/catalog.css';

import OrdSlider from '../slider/OrderSlider'
import IntSlider from '../slider/IntSlider'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function PodCategoriesPage() {
  const { id } = useParams();
  const [card, setCard] = useState(null);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [expandedFilters, setExpandedFilters] = useState({});
  const { addToCart, toggleCart } = useCart();
  const [sortOption, setSortOption] = useState('');

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  useEffect(() => {
    const fetchReviewsCount = async () => {
      try {
        const reviewsPromises = products.map(async (product) => {
          const response = await axios.get(`${baseURL}/api/reviews/count?productId=${product._id}`);
          return { id: product._id, reviewsCount: response.data.count };
        });
        const reviewsData = await Promise.all(reviewsPromises);
        setProducts((prevProducts) =>
          prevProducts.map((product) => {
            const reviewInfo = reviewsData.find((r) => r.id === product._id);
            return { ...product, reviewsCount: reviewInfo?.reviewsCount || 0 };
          })
        );
      } catch (error) {
        console.error('Помилка при завантаженні кількості відгуків:', error);
      }
    };

    if (products.length > 0) {
      fetchReviewsCount();
    }
  }, [products]);

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/catalog-cards/${id}`);
        setCard(response.data);
      } catch (error) {
        console.error('Помилка при завантаженні картки:', error);
      }
    };

    fetchCard();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/products?category=${id}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Помилка при завантаженні продуктів:', error);
      }
    };

    fetchProducts();
  }, [id]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/filters/${id}`);
        setFilters(response.data || []);
      } catch (error) {
        console.error('Помилка при завантаженні фільтрів:', error);
      }
    };

    const handleFiltersUpdated = () => {
      fetchFilters();
    };

    fetchFilters();

    window.addEventListener('filtersUpdated', handleFiltersUpdated);
    return () => {
      window.removeEventListener('filtersUpdated', handleFiltersUpdated);
    };
  }, [id]);

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev };
      updatedFilters[filterName] = updatedFilters[filterName] || [];

      if (value.checked) {
        updatedFilters[filterName].push(value.value);
      } else {
        updatedFilters[filterName] = updatedFilters[filterName].filter((val) => val !== value.value);
      }

      if (updatedFilters[filterName].length === 0) {
        delete updatedFilters[filterName];
      }

      return updatedFilters;
    });
  };

  const toggleFilter = (filterName) => {
    setExpandedFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName],
    }));
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortProducts = (products) => {
    if (sortOption === 'price-asc') {
      return [...products].sort((a, b) => a.price - b.price);
    }
    if (sortOption === 'price-desc') {
      return [...products].sort((a, b) => b.price - a.price);
    }
    if (sortOption === 'popularity') {
      return [...products].sort((a, b) => b.reviewsCount - a.reviewsCount);
    }
    if (sortOption === 'hit-sale') {
      return products.filter((product) => product.promo === 'Хіт продажу');
    }
    if (sortOption === 'top-sale') {
      return products.filter((product) => product.promo === 'Топ продажу');
    }
    if (sortOption === 'new-items') {
      return products.filter((product) => product.promo === 'Новинка');
    }
    if (sortOption === 'promo-items') {
      return products.filter((product) => product.promo === 'Акція');
    }
    return products;
  };

  const filteredProducts = sortProducts(
    products.filter((product) =>
      Object.entries(selectedFilters).every(([filterName, filterValues]) =>
        filterValues.every((filterValue) =>
          product.characteristics.some(
            (char) => char.name === filterName && char.value === filterValue
          )
        )
      )
    )
  );

  const getProductCountText = (count) => {
    if (count === 1) return 'товар';
    if (count >= 2 && count <= 4) return 'товари';
    return 'товарів';
  };

  const getReviewCountText = (count) => {
    if (count % 100 >= 11 && count % 100 <= 19) return `${count} відгуків`;
    const lastDigit = count % 10;
    if (lastDigit === 1) return `${count} відгук`;
    if (lastDigit >= 2 && lastDigit <= 4) return `${count} відгуки`;
    return `${count} відгуків`;
  };

  return (
    <div className="main-container">
      <NavPanel />
      <div className="container">
        <div className="podcategories_container">
          <div className="inner_catalog">
            <h2 className="podcategories_title">{card ? card.title : 'Завантаження...'}</h2>
            <div className="sort_container">
              <button className="filter_bar-btn" onClick={toggleSidebar}>
                <img src={iconSlider.FilterIcon} alt="Фільтри" />
                Фільтри
              </button>
              <div className={`filter_bar ${isSidebarOpen ? 'open' : ''}`}>
                <div className="sidebar-header">
                  <h3>Фільтри пошуку</h3>
                  <button className="close-sidebar-btn" onClick={toggleSidebar}>
                    <img src={iconSlider.CloseIcon} alt="Фільтри" />
                  </button>
                </div>
                <div className="filter_bar-content">
                  <p className="filter_title-bar">
                    {`Знайдено ${filteredProducts.length} ${getProductCountText(filteredProducts.length)}`}
                  </p>
                  <div className="filter_container-bar">
                    {filters.length > 0 ? (
                      filters.map((filter) => (
                        <div className="filter_item" key={filter.name}>
                          <p onClick={() => toggleFilter(filter.name)} className="filter_name">
                            {filter.name}
                            <img
                              src={iconSlider.OpenIcon}
                              alt="Toggle"
                              className={`filter_icon ${expandedFilters[filter.name] ? 'rotated' : ''}`}
                            />
                          </p>
                          {expandedFilters[filter.name] && (
                            <div className="filter_options">
                              {filter.options.map((option) => (
                                <label key={option.value} className="filter_checkbox">
                                  <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={selectedFilters[filter.name]?.includes(option.value) || false}
                                    onChange={(e) =>
                                      handleFilterChange(filter.name, {
                                        value: option.value,
                                        checked: e.target.checked,
                                      })
                                    }
                                  />
                                  {option.value}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>Фільтри не знайдено</p>
                    )}
                  </div>
                </div>
              </div>
              <p className="filter_title">
                {`Знайдено ${filteredProducts.length} ${getProductCountText(filteredProducts.length)}`}
              </p>
              <select id="sort-select" value={sortOption} onChange={handleSortChange}>
                <option value="">Без сортування</option>
                <option value="price-asc">Від дешевих до дорогих</option>
                <option value="price-desc">Від дорогих до дешевих</option>
                <option value="popularity">Популярні</option>
                <option value="hit-sale">Хіт продажу</option>
                <option value="top-sale">Топ продажу</option>
                <option value="new-items">Новинки</option>
                <option value="promo-items">Акційні товари</option>
              </select>
            </div>
            <div className="filter_grid">
              <div className="filter_container">
                {filters.length > 0 ? (
                  filters.map((filter) => (
                    <div className="filter_item" key={filter.name}>
                      <p onClick={() => toggleFilter(filter.name)} className="filter_name">
                        {filter.name}
                        <img
                          src={iconSlider.OpenIcon}
                          alt="Toggle"
                          className={`filter_icon ${expandedFilters[filter.name] ? 'rotated' : ''}`}
                        />
                      </p>
                      {expandedFilters[filter.name] && (
                        <div className="filter_options">
                          {filter.options.map((option) => (
                            <label key={option.value} className="filter_checkbox">
                              <input
                                type="checkbox"
                                value={option.value}
                                checked={selectedFilters[filter.name]?.includes(option.value) || false}
                                onChange={(e) =>
                                  handleFilterChange(filter.name, {
                                    value: option.value,
                                    checked: e.target.checked,
                                  })
                                }
                              />
                              {option.value}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Фільтри не знайдено</p>
                )}
              </div>
              <div className="categories_grid-pod">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <div key={product._id} className="categories_card">
                      <Link to={`/prodykt/${product._id}`} className="product-link">
                        <img src={product.images[0]} alt={product.title} />
                        <p className="card_product-title">{product.title}</p>
                        <p className="stock_card">
                          {product.inStock ? 'В наявності' : 'Немає в наявності'}
                          {product.promo && product.promo !== 'Без промо' && (
                            <div
                              className="promo_label"
                              style={{
                                backgroundColor:
                                  product.promo === 'Хіт продажу' || product.promo === 'Топ продажу'
                                    ? 'orange'
                                    : product.promo === 'Новинка'
                                    ? 'green'
                                    : 'red',
                              }}
                            >
                              {product.promo}
                            </div>
                          )}
                        </p>
                        <div className="color_item-card">
                          <span className="color" style={{ backgroundColor: product.color || 'transparent' }}></span>
                        </div>
                      </Link>
                      <div className="price_add-item">
                        <div className="price_current-old">
                          {product?.promo === 'Акція' && product?.oldPrice && (
                            <p className="old_price">{formatPrice(product.oldPrice)} грн</p>
                          )}
                          <p
                            className="product_price"
                            style={{
                              color: product?.promo === 'Акція' ? 'red' : 'inherit',
                            }}
                          >
                            {formatPrice(product.price)} грн
                          </p>
                        </div>
                        <button
                          className="add_btn-card"
                          onClick={(e) => {
                            e.stopPropagation();
                            addToCart({
                              id: product.id,
                              title: product.title,
                              price: product.price,
                              image: product.images[0],
                              cartId: product._id,
                            });
                            toggleCart();
                          }}
                        >
                          <img src={iconSlider.CardBag} alt="Швидке замовлення" />
                        </button>
                      </div>
                      <div className="reviews_block">
                        <img src={iconSlider.RevIcon} alt="#" />
                        <p>{getReviewCountText(product.reviewsCount || 0)}</p>
                      </div>
                      <div className="delivery_icon-title">
                        <p>Готовий до відправлення</p>
                        <img src={iconSlider.DelIcon} alt="#" />
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="nothing_found">Нічого не знайдено</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <OrdSlider />
        <IntSlider />
        <Footer />
      </div>
    </div>
  );
}

export default PodCategoriesPage;