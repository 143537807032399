import React from 'react';
import UserList from './UserList'

function UserContainer() {
  return (
    <div className='user_container'>
      <div className="inner-user_container">
        <UserList />
      </div>
    </div>
  );
}

export default UserContainer;