import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ColorPicker from '../ColorPicker';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function ProductEditForm({ onSuccess }) {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [color, setColor] = useState('');
  const [characteristics, setCharacteristics] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [notification, setNotification] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleInputFocus = () => setIsDropdownOpen(true);

  const [promo, setPromo] = useState('Без промо');
  const [oldPrice, setOldPrice] = useState('');

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleProductSelect = (product) => {
    selectProduct(product);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/products`);
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error('Помилка завантаження продуктів:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = products.filter((product) =>
      product.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const selectProduct = (product) => {
    setSelectedProduct(product);
    setTitle(product.title);
    setPrice(product.price);
    setDescription(product.description);
    setUploadedImages(product.images);
    setColor(product.color);
    setCharacteristics(product.characteristics);
  };

  const handleImageUpload = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', 'ml_default');

    try {
      const res = await axios.post(
        'https://api.cloudinary.com/v1_1/dca5poo9m/image/upload',
        formData
      );
      return res.data.secure_url;
    } catch (error) {
      console.error('Помилка завантаження зображення:', error);
      return '';
    }
  };

  const handleImagesChange = async (e) => {
    const files = Array.from(e.target.files);
    setIsUploading(true);

    const urls = await Promise.all(files.map((file) => handleImageUpload(file)));
    setUploadedImages(urls.filter((url) => url !== ''));
    setIsUploading(false);
  };

  const addCharacteristic = () => {
    setCharacteristics([...characteristics, { name: '', value: '' }]);
  };

  const handleCharacteristicChange = (index, field, value) => {
    const updatedCharacteristics = [...characteristics];
    updatedCharacteristics[index][field] = value;
    setCharacteristics(updatedCharacteristics);
  };

  const removeCharacteristic = (index) => {
    setCharacteristics(characteristics.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting product update...');
    console.log('Selected Product ID:', selectedProduct._id);
    console.log('Form Data:', { title, price, description, images: uploadedImages, color, characteristics });
  
    try {
      const response = await axios.put(`${baseURL}/api/products/${selectedProduct._id}`, {
        title,
        price,
        oldPrice: promo === 'Акція' ? oldPrice : null,
        description,
        images: uploadedImages,
        color,
        characteristics,
        promo,
      });
  
      console.log('Update response:', response.data);
      setNotification({ message: 'Продукт успішно оновлено!', type: 'success' });
  
      if (typeof onSuccess === 'function') {
        onSuccess();
      } else {
        console.warn('onSuccess пропущено або не є функцією.');
      }
    } catch (error) {
      console.error('Помилка при оновленні продукту:', error);
      setNotification({ message: 'Помилка при оновленні продукту, спробуйте ще раз.', type: 'error' });
    }
  };

  return (
    <div className='edit_form-product'>
      <div className="inner_edit-form">
        <div className="product-search" ref={dropdownRef}>
          <input
            type="text"
            placeholder="Пошук продукту за назвою..."
            value={searchQuery}
            onChange={handleSearch}
            onFocus={handleInputFocus}
          />
          {isDropdownOpen && (
            <ul className="product-list">
              {filteredProducts.map((product) => (
                <li key={product._id} onClick={() => handleProductSelect(product)}>
                  {product.title}
                </li>
              ))}
            </ul>
          )}
        </div>

        {selectedProduct && (
          <form className="edit-form" onSubmit={handleSubmit}>
            <div className="promo-section">
              <span>Тип промо:</span>
              <select value={promo} onChange={(e) => setPromo(e.target.value)}>
                <option value="Без промо">Без промо</option>
                <option value="Хіт продажу">Хіт продажу</option>
                <option value="Топ продажу">Топ продажу</option>
                <option value="Новинка">Новинка</option>
                <option value="Акція">Акція</option>
              </select>
            </div>
            <span>Нова назва продукту:</span>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
            <span>Нова ціна:</span>
            <input
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              style={{ color: promo === 'Акція' ? 'red' : 'black' }}
            />
            {promo === 'Акція' && (
              <div className="old-price-section">
                <span>Стара ціна:</span>
                <input
                  type="text"
                  value={oldPrice}
                  onChange={(e) => setOldPrice(e.target.value)}
                  required
                />
              </div>
            )}
            <span>Новий опис продукту:</span>
            <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
            <div className="custom-file-upload">
              <input
                type="file"
                multiple
                onChange={handleImagesChange}
                id="file-upload"
                style={{ display: 'none' }}
              />
              <label className="file-upload" htmlFor="file-upload">
                <img
                  src={iconSlider.DwnIcon}
                  alt="Upload Icon"
                  className="upload-preview"
                />
                <p>
                  {isUploading
                    ? 'Завантаження...'
                    : (uploadedImages.length > 0
                      ? `Завантажено картинок ${uploadedImages.length}`
                      : 'Завантажити картинки')}
                </p>
              </label>
              <p className='upload_title'>Замінити картинки (якщо потрібно)</p>
            </div>

            {uploadedImages.length > 0 && (
              <div className="uploaded-images">
                {uploadedImages.map((url, index) => (
                  <img key={index} src={url} alt={`Зображення ${index + 1}`} />
                ))}
              </div>
            )}
            <ColorPicker onSelectColor={setColor} currentColor={color} />
            <div className="characteristics-section">
              <h3>Оновити\Додати характеристики</h3>
              {characteristics.map((char, index) => (
                <div className='inputs_container' key={index}>
                  <input
                    type="text"
                    placeholder="Назва"
                    value={char.name}
                    onChange={(e) => handleCharacteristicChange(index, 'name', e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Значення"
                    value={char.value}
                    onChange={(e) => handleCharacteristicChange(index, 'value', e.target.value)}
                    required
                  />
                  <button type="button" className="remove-char-btn" onClick={() => removeCharacteristic(index)}><img src={iconSlider.DeleteIcon} /></button>
                </div>
              ))}
              <button className='char_btn-plus' type="button" onClick={addCharacteristic}>Додати характеристику</button>
            </div>
            <button className='update' type="submit">Оновити продукт</button>
            {notification && <div className={`notification ${notification.type}`}>{notification.message}</div>}
          </form>
        )}
      </div>
    </div>
  );
}

export default ProductEditForm;