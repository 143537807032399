import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './cabinet.css'
import MainInfo from './MainInfoTab';
import Orders from './OrdersTab';
import Wishlist from './WishlistTab';

import SettingTab from './Setting';

const UserTabs = () => {
  const [activeTab, setActiveTab] = useState('mainInfo');
  const history = useNavigate();

  const renderTabContent = () => {
    switch (activeTab) {
      case 'mainInfo':
        return <MainInfo />;
      case 'orders':
        return <Orders />;
      case 'wishlist':
        return <Wishlist />;
      case 'settings':
        return <SettingTab />;
      default:
        return null;
    }
  };

  return (
    <div className="user_tabs">
      <div className="tab_user-buttons">
        <div className="inner_user-button">
          <button onClick={() => setActiveTab('mainInfo')} className={activeTab === 'mainInfo' ? 'active' : ''}>Особисті дані</button>
          <button onClick={() => setActiveTab('wishlist')} className={activeTab === 'wishlist' ? 'active' : ''}>Обране</button>
          <button onClick={() => setActiveTab('settings')} className={activeTab === 'settings' ? 'active' : ''}>Налаштування</button>
        </div>
      </div>
      <div className="tab_user-content">
        <div className="inner_tab-user">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default UserTabs;