import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import iconSlider from '../../../icon/other-icon/other-icon'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function SEOManager() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [seoData, setSeoData] = useState([]);
  const [editingSeo, setEditingSeo] = useState(null);
  const [newSeo, setNewSeo] = useState({
    title: '',
    description: '',
    keywords: '',
  });

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get(`${baseURL}/api/products`);
        setProducts(res.data);
        setFilteredProducts(res.data);
      } catch (error) {
        console.error('Помилка завантаження продуктів:', error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(e.target) &&
        inputRef.current && !inputRef.current.contains(e.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = products.filter((product) =>
      product.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProducts(filtered);
    setIsDropdownOpen(true);
  };

  const handleProductSelect = async (product) => {
    setSelectedProduct(product);

    try {
      const res = await axios.get(`${baseURL}/api/products/${product._id}/seo`);
      const data = Array.isArray(res.data) ? res.data : [res.data];
      setSeoData(data);
    } catch (error) {
      console.error('Помилка завантаження SEO даних:', error);
      setSeoData([]);
    }

    setIsDropdownOpen(false);
  };

  const handleEditSeo = (index) => {
    setEditingSeo(index);
  };

  const handleSeoChange = (index, field, value) => {
    const updatedSeoData = [...seoData];
    updatedSeoData[index][field] = value;
    setSeoData(updatedSeoData);
  };

  const handleSaveSeo = async (index) => {
    const seo = seoData[index];

    try {
      await axios.post(`${baseURL}/api/products/${selectedProduct._id}/seo`, {
        title: seo.title,
        description: seo.description,
        keywords: seo.keywords,
      });
      alert('SEO дані успішно збережено!');
      setEditingSeo(null);
    } catch (error) {
      console.error('Помилка збереження SEO даних:', error);
      alert('Не вдалося зберегти SEO дані.');
    }
  };

  const handleCreateSeo = async () => {
    try {
      await axios.post(`${baseURL}/api/products/${selectedProduct._id}/seo`, newSeo);
      alert('Нові SEO дані успішно створено!');
      setNewSeo({ title: '', description: '', keywords: '' });
      const res = await axios.get(`${baseURL}/api/products/${selectedProduct._id}/seo`);
      setSeoData(Array.isArray(res.data) ? res.data : [res.data]);
    } catch (error) {
      console.error('Помилка створення SEO даних:', error);
      alert('Не вдалося створити нові SEO дані.');
    }
  };

  return (
    <div className="seo_manager">
      <div className="inner_manager">
        <p>Налаштування SEO для сторінок продукту</p>
        <div className="product-selector" ref={dropdownRef}>
          <input
            ref={inputRef}
            type="text"
            placeholder="Пошук продукту..."
            value={searchQuery}
            onChange={handleSearch}
            onFocus={() => setIsDropdownOpen(true)}
          />
          {isDropdownOpen && (
            <ul className="product-list">
              {filteredProducts.map((product) => (
                <li key={product._id} onClick={() => handleProductSelect(product)}>
                  {product.title}
                </li>
              ))}
            </ul>
          )}
        </div>

        {selectedProduct && (
          <div className="seo-list">
            <h3>SEO для продукту - ( {selectedProduct.title} )</h3>
            {Array.isArray(seoData) && seoData.length > 0 ? (
              seoData.map((seo, index) => (
                <div key={index} className="seo-item">
                  {editingSeo === index ? (
                    <div className="seo-edit">
                      <label>
                        Заголовок
                        <input
                          type="text"
                          value={seo.title}
                          onChange={(e) => handleSeoChange(index, 'title', e.target.value)}
                        />
                      </label>
                      <label>
                        Опис
                        <textarea
                          value={seo.description}
                          onChange={(e) => handleSeoChange(index, 'description', e.target.value)}
                        />
                      </label>
                      <label>
                        Ключові слова
                        <input
                          type="text"
                          value={seo.keywords}
                          onChange={(e) => handleSeoChange(index, 'keywords', e.target.value)}
                        />
                      </label>
                      <button className='save_seo-btn' onClick={() => handleSaveSeo(index)}>Зберегти</button>
                      <button className='save_seo-btn' onClick={() => setEditingSeo(null)}>Скасувати</button>
                    </div>
                  ) : (
                    <div className="seo-view">
                      <p>Заголовок <br /> {seo.title}</p>
                      <p>Опис <br /> {seo.description}</p>
                      <p>Ключові слова <br /> {seo.keywords}</p>
                      <button className='update_seo-btn' onClick={() => handleEditSeo(index)}>
                        Редагувати
                        <img src={iconSlider.PenIcon} alt="Редагувати" />
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="seo-create">
                <h4>Додати нові SEO дані</h4>
                <label>
                  Заголовок:
                  <input
                    type="text"
                    value={newSeo.title}
                    onChange={(e) => setNewSeo({ ...newSeo, title: e.target.value })}
                  />
                </label>
                <label>
                  Опис:
                  <textarea
                    value={newSeo.description}
                    onChange={(e) => setNewSeo({ ...newSeo, description: e.target.value })}
                  />
                </label>
                <label>
                  Ключові слова (через кому):
                  <input
                    type="text"
                    value={newSeo.keywords}
                    onChange={(e) => setNewSeo({ ...newSeo, keywords: e.target.value })}
                  />
                </label>
                <button className='save_seo-btn' onClick={handleCreateSeo}>Зберегти налаштування SEO</button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SEOManager;