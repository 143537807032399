import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import VisitsDatePicker from './VisitsDatePicker';
import MapComponent from './MapComponent';
import CityVisits from './CityVisits';
import UserCount from './UserCount'
import UserCountReg from './UserCountReg'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const VisitChart = () => {
  const [data, setData] = useState({ labels: [], datasets: [] });
  const [visitList, setVisitList] = useState([]);
  const [totalVisits, setTotalVisits] = useState(0);
  const [averageVisitsToday, setAverageVisitsToday] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dailyVisits, setDailyVisits] = useState(0);
  const [dailyAverageVisits, setDailyAverageVisits] = useState(0);

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchAverageVisitsToday = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/average-visits-today`);
        setAverageVisitsToday(response.data.averageVisits);
      } catch (error) {
        console.error('Error fetching average visits for today:', error);
      }
    };

    fetchAverageVisitsToday();
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}/api/visits`)
      .then((response) => {
        const fetchedData = response.data;
        const labels = fetchedData.map((item) => item.hour);
        const counts = fetchedData.map((item) => item.count);
        const total = counts.reduce((sum, count) => sum + (count || 0), 0);

        const currentHour = new Date().getHours();
        const listData = labels
          .map((hour, index) => ({
            hourStart: hour,
            hourEnd: index < labels.length - 1 ? labels[index + 1] : '00:00:00',
            count: counts[index],
          }))
          .filter((item, index) => index < currentHour);

        setData({
          labels,
          datasets: [
            {
              label: 'Відвідування за годинами',
              data: counts,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        });

        setVisitList(listData);
        setTotalVisits(total);

        if (selectedDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]) {
          setDailyVisits(total);
          setDailyAverageVisits(total / 24);
        }
      })
      .catch((error) => console.error('Error fetching visits:', error));
  }, [selectedDate]);

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    const formattedDate = date.toISOString().split('T')[0];
  
    try {
      const response = await axios.get(`${baseURL}/api/visits-by-date?date=${formattedDate}`);
      const { totalVisits, hourlyCounts } = response.data;
  
      setDailyVisits(totalVisits);
      setData({
        labels: Array.from({ length: 24 }, (_, i) => `${i < 10 ? '0' : ''}${i}:00`),
        datasets: [
          {
            label: 'Відвідування за годинами',
            data: hourlyCounts,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.error('Error fetching daily visits:', error);
    }
  };

  useEffect(() => {
    if (selectedDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]) {
      setDailyVisits(totalVisits);
    }
  }, [selectedDate, totalVisits]);

  useEffect(() => {
    const incrementVisitCount = async () => {
      try {
        await axios.post(`${baseURL}/api/user-data/increment-visits`);
      } catch (error) {
        console.error('Error incrementing visit count:', error);
      }
    };

    incrementVisitCount();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `Кількість: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Час',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Відвідувань',
        },
        beginAtZero: true,
      },
    },
    elements: {
      bar: {
        borderRadius: 5,
      },
    },
  };

  return (
    <div className="visit-chart">
      <div className="user_item-row">
        <UserCount />
        <UserCountReg />
      </div>
      <div className="chart-body">
        <div className="inner_chart">
          <Bar data={data} options={options} />
        </div>
      </div>
      <div className="list_header-col">
        <div className="time_count-col">
          <div className="time_count-row">
            <div className="header-visit">
              <div className="inner_header-visit">
                <h3>Відвідувань за поточну добу: {totalVisits}</h3>
              </div>
            </div>
            <div className="average-visits">
              <div className="inner_average-visits">
                <h3>Середнє відвідування за годину: {averageVisitsToday.toFixed(2)}</h3>
              </div>
            </div>
          </div>
          <div className="average-bar-chart-container">
            <div className="inner-bar_chart">
              <div className="average-bar-chart">
                {Array.from({ length: 24 }).map((_, index) => {
                  const currentHourVisit = visitList[index]?.count || 0;

                  return (
                    <div className="bar-container" key={index}>
                      <div className="average-visit-label">
                        <h3>{currentHourVisit}</h3>
                      </div>
                      <div
                        className="bar"
                        style={{
                          height: `${4 + currentHourVisit * 10}px`,
                          backgroundColor: '#000',
                          borderRadius: '4px',
                          width: '20px',
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ul className="visit-list">
          <div className="inner_visit-list">
            {visitList.map((item, index) => (
              <li key={index} className="visit-list-item">
                <p>{`${formatTime(item.hourStart)} - ${formatTime(item.hourEnd)}`}</p>
                <p>{item.count} відвідувань</p>
              </li>
            ))}
          </div>
        </ul>
      </div>

    { //<div className="date-container">
        //<VisitsDatePicker selectedDate={selectedDate} onDateChange={handleDateChange} />
        //<div className="daily-stats">
          //<div className="inner_daily-stats">
            //<h3>
              //Відвідування за вибраний день: {dailyVisits}
            //</h3>
         //</div>
       //</div>
      //</div>
    }
      <div className="map_container">
        <div className="inner_map">
          <MapComponent />
          <CityVisits />
        </div>
      </div>
    </div>
  );
};

export default VisitChart;