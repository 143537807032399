import React, { useState, useEffect } from 'react';
import axios from 'axios';
import iconLi from '../../../icon/icon-li';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function CardForm({ onCardAdded }) {
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [selectedEditCard, setSelectedEditCard] = useState('');
  const [selectedDeleteCard, setSelectedDeleteCard] = useState('');
  const [showIcons, setShowIcons] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [notification, setNotification] = useState(null);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/cards`);
        setCards(response.data);
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    };
    fetchCards();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      setFileName(file.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!text || !selectedIcon) {
      return alert('Будь ласка, заповніть всі поля та оберіть іконку');
    }

    try {
      setLoading(true);
      let finalImageUrl = '';

      if (image) {
        const formData = new FormData();
        formData.append('file', image);
        formData.append('upload_preset', 'ml_default');

        const uploadRes = await axios.post(
          'https://api.cloudinary.com/v1_1/dca5poo9m/image/upload',
          formData
        );
        finalImageUrl = uploadRes.data.secure_url;
      }

      const cardData = { text, imageUrl: finalImageUrl, icon: selectedIcon };

      if (selectedEditCard) {
        await axios.put(`${baseURL}/api/cards/${selectedEditCard}`, cardData);
        setCards((prev) =>
          prev.map((card) =>
            card._id === selectedEditCard ? { ...card, ...cardData } : card
          )
        );
        showNotification('Картка успішно оновлена!', 'success');
      } else {
        const response = await axios.post(`${baseURL}/api/cards`, cardData);
        setCards((prevCards) => [...prevCards, response.data]);
        showNotification('Картка успішно додана!', 'success');
      }

      resetForm();
    } catch (error) {
      console.error('Error saving card:', error);
      showNotification('Помилка збереження картки, спробуйте ще раз.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${baseURL}/api/cards/${selectedDeleteCard}`);
      setCards((prevCards) =>
        prevCards.filter((card) => card._id !== selectedDeleteCard)
      );
      setSelectedDeleteCard('');
      showNotification('Картка успішно видалена!', 'success');
    } catch (error) {
      console.error('Error deleting card:', error);
      showNotification('Помилка при видаленні картки, спробуйте ще раз.', 'error');
    }
  };

  const handleCardSelect = (cardId) => {
    const card = cards.find((c) => c._id === cardId);
    if (card) {
      setText(card.text);
      setSelectedIcon(card.icon);
      setSelectedEditCard(cardId);
    }
  };

  const resetForm = () => {
    setText('');
    setImage(null);
    setSelectedIcon('');
    setShowIcons(false);
    setSelectedEditCard('');
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className='create_container'>
      <div className="inner_create">
        <div className='create'>
          <p className='create_title'>Створити категорію каталогу</p>
          <form className="create_form" onSubmit={handleSubmit}>
            <span>Назва категорії</span>
            <input
              type="text"
              placeholder="Наприклад: 'Побутова техніка або Товари для геймерів'"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />

            <div className="custom-file-upload">
              <input
                type="file"
                onChange={handleFileChange}
                id="file-upload"
                style={{ display: 'none' }}
              />
              <label className='file-upload' htmlFor="file-upload">
                <img
                  src={iconSlider.DwnIcon}
                  alt="Upload Icon"
                  className="upload-preview"
                />
                <p>{fileName || 'Завантажити картинку'}</p>
              </label>

              <button className='icon_btn' type="button" onClick={() => setShowIcons(!showIcons)}>
                {showIcons ? 'Сховати іконки' : 'Вибрати іконку'}
              </button>
            </div>

            {showIcons && (
              <div className="icon-list">
                {Object.entries(iconLi).map(([key, icon]) => (
                  <img
                    key={key}
                    src={icon}
                    alt={key}
                    style={{
                      cursor: 'pointer',
                      margin: '5px',
                      width: '30px',
                      border: selectedIcon === icon ? '2px solid blue' : 'none',
                      borderRadius: '5px',
                    }}
                    onClick={() => setSelectedIcon(icon)}
                    className={selectedIcon === icon ? 'selected' : ''}
                  />
                ))}
              </div>
            )}

            <button className='create_btn' type="submit" disabled={loading}>
              {loading ? 'Завантаження...' : selectedEditCard ? 'Оновити картку' : 'Створити категорію'}
            </button>
          </form>
          
          <p className='select_title'>Редагувати категорію</p>
          <select onChange={(e) => handleCardSelect(e.target.value)} value={selectedEditCard}>
            <option value="">Виберіть картку для редагування</option>
            {cards.map((card) => (
              <option key={card._id} value={card._id}>
                {card.text}
              </option>
            ))}
          </select>

          <p className='select_title'>Видалити категорію</p>
          <select
            onChange={(e) => setSelectedDeleteCard(e.target.value)}
            value={selectedDeleteCard}
          >
            <option value="">Виберіть картку для видалення</option>
            {cards.map((card) => (
              <option key={card._id} value={card._id}>
                {card.text}
              </option>
            ))}
          </select>

          <button className='delete-btn' onClick={handleDelete} disabled={!selectedDeleteCard}>Видалити категорію</button>

          {notification && (
            <div className={`notification ${notification.type}`}>
              {notification.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CardForm;