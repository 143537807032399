import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './cabinet.css';

const Wishlist = ({ onRemoveFromWishlist }) => {
  const [wishlist, setWishlist] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  const handleCheckboxChange = (uniqueId) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [uniqueId]: !prevCheckedItems[uniqueId],
    }));
  };

  const deleteCheckedItems = () => {
    const itemsToRemove = Object.keys(checkedItems).filter((uniqueId) => checkedItems[uniqueId]);

    const newWishlist = wishlist.filter(
      (item) => !itemsToRemove.includes(`${item.id}-${item.pageId}`)
    );

    setWishlist(newWishlist);
    localStorage.setItem('wishlist', JSON.stringify(newWishlist));
    setCheckedItems({});

    if (onRemoveFromWishlist) {
      itemsToRemove.forEach((uniqueId) => onRemoveFromWishlist(uniqueId));
    }

    if (newWishlist.length === 0) {
      localStorage.removeItem('wishlist');
    }
  };

  return (
    <div className='wish_tab-info'>
      <h2>Обране</h2>
      {Object.values(checkedItems).some((isChecked) => isChecked) && (
        <button onClick={deleteCheckedItems} className="delete_wish-btn">
          Видалити відмічені
        </button>
      )}
      <div className="grid_wish-container">
        {wishlist.length > 0 ? (
          wishlist.map((item) => {
            const uniqueId = `${item.id}-${item.pageId}`;
            return (
              <div key={uniqueId} className="wish_card">
                <input
                  type="checkbox"
                  checked={checkedItems[uniqueId] || false}
                  onChange={() => handleCheckboxChange(uniqueId)}
                />
                <Link to={item.url}>
                  <img className='wish_image-card' src={item.image} alt={item.name} />
                  <p className="wish_title-text__card">{item.name}</p>
                </Link>
              </div>
            );
          })
        ) : (
          <p>Список обраного пустий :(</p>
        )}
      </div>
    </div>
  );
};

export default Wishlist;