import React, { useEffect, useState } from 'react';
import axios from 'axios';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateCard = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [notification, setNotification] = useState(null);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [additionalBlocks, setAdditionalBlocks] = useState([]);
  const [cards, setCards] = useState([]);
  const [editingCard, setEditingCard] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(`${baseURL}/api/categories`);
        setCategories(res.data);
      } catch (error) {
        console.error('Помилка завантаження категорій:', error);
      }
    };

    fetchCategories();
  }, []);

  const fetchCards = async (categoryId) => {
    try {
      const res = await axios.get(`${baseURL}/api/catalog-cards`, { params: { categoryId } });
      setCards(res.data);
    } catch (error) {
      console.error('Помилка завантаження карток:', error);
    }
  };

  const handleFileChange = (e, index = null) => {
    const file = e.target.files[0];
    if (index === null) {
      setImage(file);
      if (file) setFileName(file.name);
    } else {
      const updatedBlocks = [...additionalBlocks];
      updatedBlocks[index].image = file;
      updatedBlocks[index].fileName = file?.name || '';
      setAdditionalBlocks(updatedBlocks);
    }
  };

  const handleImageUpload = async (imageFile) => {
    if (!imageFile) return '';

    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('upload_preset', 'ml_default');

    try {
      const res = await axios.post(
        'https://api.cloudinary.com/v1_1/dca5poo9m/image/upload',
        formData
      );
      return res.data.secure_url;
    } catch (error) {
      console.error('Помилка завантаження зображення:', error);
      return '';
    }
  };

  const addBlock = () => {
    setAdditionalBlocks([...additionalBlocks, { title: '', image: null, fileName: '' }]);
  };

  const removeBlock = (index) => {
    setAdditionalBlocks(additionalBlocks.filter((_, i) => i !== index));
  };

  const handleAdditionalInputChange = (e, index) => {
    const updatedBlocks = [...additionalBlocks];
    updatedBlocks[index].title = e.target.value;
    setAdditionalBlocks(updatedBlocks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const mainImageUrl = await handleImageUpload(image);
    if (!mainImageUrl) {
      alert('Не вдалося завантажити зображення для основної картки');
      setLoading(false);
      return;
    }

    const additionalCards = await Promise.all(
      additionalBlocks.map(async (block) => {
        const imageUrl = await handleImageUpload(block.image);
        return {
          title: block.title,
          imageUrl,
        };
      })
    );

    try {
      const allCards = [{ title, imageUrl: mainImageUrl }, ...additionalCards];
      const response = await axios.post(`${baseURL}/api/catalog-cards/batch`, {
        cards: allCards.map((card) => ({ ...card, categoryId: selectedCategory })),
      });

      showNotification('Картки успішно створені!', 'success');
      setTitle('');
      setImage(null);
      setFileName('');
      setAdditionalBlocks([]);
    } catch (error) {
      console.error('Помилка при створенні карток:', error);
      showNotification('Помилка при створенні карток, спробуйте ще раз.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditCard = async (card) => {
    const updatedImageUrl = card.image ? await handleImageUpload(card.image) : card.imageUrl;

    try {
      const updatedCard = await axios.put(`${baseURL}/api/catalog-cards/${card._id}`, {
        title: card.title,
        imageUrl: updatedImageUrl,
      });
      setCards(cards.map((c) => (c._id === card._id ? updatedCard.data : c)));
      showNotification('Картка оновлена!', 'success');
    } catch (error) {
      console.error('Помилка при оновленні картки:', error);
      showNotification('Помилка при оновленні картки.', 'error');
    }
  };

  const handleDeleteCard = async (cardId) => {
    try {
      await axios.delete(`${baseURL}/api/catalog-cards/${cardId}`);
      setCards(cards.filter((card) => card._id !== cardId));
      showNotification('Картка видалена!', 'success');
    } catch (error) {
      console.error('Помилка при видаленні картки:', error);
      showNotification('Помилка при видаленні картки.', 'error');
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className='create_container'>
      <div className="inner_create">
        <div className='create'>
          <p className='create_title'>Створити сторінку категорії</p>
          <form className="create_form" onSubmit={handleSubmit}>
            <span>Оберіть категорію каталогу</span>
            <select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                fetchCards(e.target.value);
              }}
              required
            >
              <option value="">Виберіть сторінку каталогу</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.title}
                </option>
              ))}
            </select>

            <div className="create_categori-container__row">
              <div className="row_input-input">
                <p>Назва категорії</p>
                <input
                  type="text"
                  placeholder="Наприклад: 'Холодильники або Монітори'"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>

              <div className="custom_file__upload">
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="file-upload"
                  style={{ display: 'none' }}
                  required
                />
                <label className='file-upload' htmlFor="file-upload">
                  <img
                    src={iconSlider.DwnIcon}
                    alt="Upload Icon"
                    className="upload-preview"
                  />
                  <p>{fileName || 'Завантажити картинку'}</p>
                </label>
              </div>
            </div>

            {additionalBlocks.map((block, index) => (
              <div key={index} className="additional-block">
                <div className="additional-block_input__row">
                  <div>Назва категорії</div>
                  <input
                    type="text"
                    placeholder="Назва категорії"
                    value={block.title}
                    onChange={(e) => handleAdditionalInputChange(e, index)}
                    required
                  />
                </div>

                <div className="custom_file__upload">
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, index)}
                    id={`file-upload-${index}`}
                    style={{ display: 'none' }}
                    required
                  />
                  <label className='file-upload' htmlFor={`file-upload-${index}`}>
                    <img
                      src={iconSlider.DwnIcon}
                      alt="Upload Icon"
                      className="upload-preview"
                    />
                    <p>{block.fileName || 'Завантажити картинку'}</p>
                  </label>
                </div>

                <button type="button" onClick={() => removeBlock(index)} className="remove-block-btn">
                  <img src={iconSlider.DeleteIcon} alt="#" />
                </button>
              </div>
            ))}

            <button type="button" onClick={addBlock} className="add-block-btn">
              Додати ще категорію
            </button>

            <button className='create_btn' type="submit" disabled={loading}>
              {loading ? 'Завантаження...' : 'Створити сторінки'}
            </button>
          </form>

          {notification && (
            <div className={`notification ${notification.type}`}>
              {notification.message}
            </div>
          )}

          {selectedCategory && cards.length > 0 && (
            <div className="edit-section">
              <h2>Редагувати картки</h2>
              {cards.map((card) => (
                <div key={card._id} className="card-edit-block">
                  <div className="card-edit-block_title">
                    <p>Нова назва</p>
                    <input
                      type="text"
                      value={card.title}
                      onChange={(e) => setEditingCard({ ...card, title: e.target.value })}
                    />
                  </div>
                  <div className="custom_file__upload">
                    <input
                      type="file"
                      onChange={(e) => setEditingCard({ ...card, image: e.target.files[0] })}
                      id={`edit-file-upload-${card._id}`}
                      style={{ display: 'none' }}
                    />
                    <label className='file-upload' htmlFor={`edit-file-upload-${card._id}`}>
                      <img
                        src={iconSlider.DwnIcon}
                        alt="Upload Icon"
                        className="upload-preview"
                      />
                      <p>Нова картинка</p>
                    </label>
                  </div>
                  <button onClick={() => handleEditCard(editingCard)}>Оновити</button>
                </div>
              ))}
            </div>
          )}

          {selectedCategory && cards.length > 0 && (
            <div className="delete-section">
              <h2>Видалити картки</h2>
              {cards.map((card) => (
                <div key={card._id} className="card-delete-block">
                  <span>{card.title}</span>
                  <button onClick={() => handleDeleteCard(card._id)}><img src={iconSlider.DeleteIcon} alt="#" /></button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCard;
