import React, { useState } from 'react';
import CreateCatalog from './CreatePage/Createcatalog';
import CreateCategoryPage from './CreatePage/CreateCategoryPage';
import CreateCard from './CreatePage/CreateCard';
import ProductTab from './CreatePage/ProducTab';
import FooterLinks from './FooterLinks';
import CreateFilter from './CreateFilter/CreateFilter';
import SliderTab from './SliderTab';
import TabLineSlider from './CreateSliderLine/TabLineSlide';

const ContentTabs = ({ onLinkCreated }) => {
  const [activeInnerTab, setActiveInnerTab] = useState('createCatalog');

  const innerTabs = [
    { id: 'createCatalog', label: 'Каталог', content: <CreateCatalog onLinkCreated={onLinkCreated} /> },
    { id: 'createCategory', label: 'Сторінка каталогу', content: <CreateCategoryPage /> },
    { id: 'createCard', label: 'Сторінка категорії', content: <CreateCard /> },
    { id: 'createProduct', label: 'Сторінка продукту', content: <ProductTab /> },
    { id: 'createFilter', label: 'Фільтри продуктів', content: <CreateFilter /> },
    { id: 'sliders', label: 'Слайдери', content: <SliderTab /> },
    { id: 'sliderLine', label: 'Рекомендовані категорії', content: <TabLineSlider /> },
    { id: 'footerLinks', label: 'Соц-мережі/телефони', content: <FooterLinks /> },
  ];

  return (
    <div>
      <div className="tab-select-wrapper">
        <div className="inner_select-wrapper">
          <label htmlFor="tab-select">Оберіть розділ для створення\редагування</label>
          <select
            id="tab-select"
            value={activeInnerTab}
            onChange={(e) => setActiveInnerTab(e.target.value)}
          >
            {innerTabs.map((tab) => (
              <option key={tab.id} value={tab.id}>
                {tab.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="inner_tab_content">
        {innerTabs.find((tab) => tab.id === activeInnerTab)?.content}
      </div>
    </div>
  );
};

export default ContentTabs;