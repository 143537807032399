import React, { useState } from 'react';

const ColorPicker = ({ onSelectColor }) => {
    const [showColors, setShowColors] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [tooltip, setTooltip] = useState({ visible: false, name: '', x: 0, y: 0 });

  const colors = [
    { name: 'Pink', hex: '#FFC0CB' },
    { name: 'LightPink', hex: '#FFB6C1' },
    { name: 'HotPink', hex: '#FF69B4' },
    { name: 'DeepPink', hex: '#FF1493' },
    { name: 'PaleVioletRed', hex: '#DB7093' },
    { name: 'MediumVioletRed', hex: '#C71585' },
    { name: 'Lavender', hex: '#E6E6FA' },
    { name: 'Thistle', hex: '#D8BFD8' },
    { name: 'Plum', hex: '#DDA0DD' },
    { name: 'Fuchsia', hex: '#FF00FF' },
    { name: 'LightSalmon', hex: '#FFA07A' },
    { name: 'Salmon', hex: '#FA8072' },
    { name: 'DarkSalmon', hex: '#E9967A' },
    { name: 'LightCoral', hex: '#F08080' },
    { name: 'IndianRed', hex: '#CD5C5C' },
    { name: 'Crimson', hex: '#DC143C' },
    { name: 'Red', hex: '#FF0000' },
    { name: 'FireBrick', hex: '#B22222' },
    { name: 'DarkRed', hex: '#8B0000' },
    { name: 'Orange', hex: '#FFA500' },
    { name: 'DarkOrange', hex: '#FF8C00' },
    { name: 'Coral', hex: '#FF7F50' },
    { name: 'Tomato', hex: '#FF6347' },
    { name: 'OrangeRed', hex: '#FF4500' },
    { name: 'Gold', hex: '#FFD700' },
    { name: 'Yellow', hex: '#FFFF00' },
    { name: 'LightYellow', hex: '#FFFFE0' },
    { name: 'LemonChiffon', hex: '#FFFACD' },
    { name: 'LightGoldenRodYellow', hex: '#FAFAD2' },
    { name: 'PapayaWhip', hex: '#FFEFD5' },
    { name: 'Moccasin', hex: '#FFE4B5' },
    { name: 'PeachPuff', hex: '#FFDAB9' },
    { name: 'PaleGoldenRod', hex: '#EEE8AA' },
    { name: 'Khaki', hex: '#F0E68C' },
    { name: 'DarkKhaki', hex: '#BDB76B' },
    { name: 'GreenYellow', hex: '#ADFF2F' },
    { name: 'Chartreuse', hex: '#7FFF00' },
    { name: 'LawnGreen', hex: '#7CFC00' },
    { name: 'Lime', hex: '#00FF00' },
    { name: 'LimeGreen', hex: '#32CD32' },
    { name: 'PaleGreen', hex: '#98FB98' },
    { name: 'LightGreen', hex: '#90EE90' },
    { name: 'MediumSpringGreen', hex: '#00FA9A' },
    { name: 'SpringGreen', hex: '#00FF7F' },
    { name: 'MediumSeaGreen', hex: '#3CB371' },
    { name: 'SeaGreen', hex: '#2E8B57' },
    { name: 'ForestGreen', hex: '#228B22' },
    { name: 'Green', hex: '#008000' },
    { name: 'DarkGreen', hex: '#006400' },
    { name: 'YellowGreen', hex: '#9ACD32' },
    { name: 'OliveDrab', hex: '#6B8E23' },
    { name: 'DarkOliveGreen', hex: '#556B2F' },
    { name: 'MediumAquaMarine', hex: '#66CDAA' },
    { name: 'DarkSeaGreen', hex: '#8FBC8F' },
    { name: 'LightSeaGreen', hex: '#20B2AA' },
    { name: 'DarkCyan', hex: '#008B8B' },
    { name: 'Teal', hex: '#008080' },
    { name: 'Aqua', hex: '#00FFFF' },
    { name: 'Cyan', hex: '#00FFFF' },
    { name: 'LightCyan', hex: '#E0FFFF' },
    { name: 'PaleTurquoise', hex: '#AFEEEE' },
    { name: 'Aquamarine', hex: '#7FFFD4' },
    { name: 'Turquoise', hex: '#40E0D0' },
    { name: 'MediumTurquoise', hex: '#48D1CC' },
    { name: 'DarkTurquoise', hex: '#00CED1' },
    { name: 'CadetBlue', hex: '#5F9EA0' },
    { name: 'SteelBlue', hex: '#4682B4' },
    { name: 'LightSteelBlue', hex: '#B0C4DE' },
    { name: 'LightBlue', hex: '#ADD8E6' },
    { name: 'PowderBlue', hex: '#B0E0E6' },
    { name: 'LightSkyBlue', hex: '#87CEFA' },
    { name: 'SkyBlue', hex: '#87CEEB' },
    { name: 'CornflowerBlue', hex: '#6495ED' },
    { name: 'DeepSkyBlue', hex: '#00BFFF' },
    { name: 'DodgerBlue', hex: '#1E90FF' },
    { name: 'RoyalBlue', hex: '#4169E1' },
    { name: 'Blue', hex: '#0000FF' },
    { name: 'MediumBlue', hex: '#0000CD' },
    { name: 'DarkBlue', hex: '#00008B' },
    { name: 'Navy', hex: '#000080' },
    { name: 'MidnightBlue', hex: '#191970' },
    { name: 'Cornsilk', hex: '#FFF8DC' },
    { name: 'BlanchedAlmond', hex: '#FFEBCD' },
    { name: 'Bisque', hex: '#FFE4C4' },
    { name: 'NavajoWhite', hex: '#FFDEAD' },
    { name: 'Wheat', hex: '#F5DEB3' },
    { name: 'BurlyWood', hex: '#DEB887' },
    { name: 'Tan', hex: '#D2B48C' },
    { name: 'RosyBrown', hex: '#BC8F8F' },
    { name: 'SandyBrown', hex: '#F4A460' },
    { name: 'GoldenRod', hex: '#DAA520' },
    { name: 'DarkGoldenRod', hex: '#B8860B' },
    { name: 'Peru', hex: '#CD853F' },
    { name: 'Chocolate', hex: '#D2691E' },
    { name: 'FireBrick', hex: '#B22222' },
    { name: 'Brown', hex: '#A52A2A' },
    { name: 'Sienna', hex: '#A0522D' },
    { name: 'Brown', hex: '#964B00' },
    { name: 'Maroon', hex: '#800000' },
    { name: 'White', hex: '#FFFFFF' },
    { name: 'Snow', hex: '#FFFAFA' },
    { name: 'HoneyDew', hex: '#F0FFF0' },
    { name: 'MintCream', hex: '#F5FFFA' },
    { name: 'Azure', hex: '#F0FFFF' },
    { name: 'AliceBlue', hex: '#F0F8FF' },
    { name: 'LavenderBlush', hex: '#FFF0F5' },
    { name: 'MistyRose', hex: '#FFE4E1' },
    { name: 'Gainsboro', hex: '#DCDCDC' },
    { name: 'LightGray', hex: '#D3D3D3' },
    { name: 'Silver', hex: '#C0C0C0' },
    { name: 'DarkGray', hex: '#A9A9A9' },
    { name: 'DimGray', hex: '#696969' },
    { name: 'Gray', hex: '#808080' },
    { name: 'LightSlateGray', hex: '#778899' },
    { name: 'SlateGray', hex: '#708090' },
    { name: 'DarkSlateGray', hex: '#2F4F4F' },
    { name: 'Black', hex: '#000000' },
  ];

  return (
    <div className='color_container'>
        <button className='color_btn' type="button" onClick={() => setShowColors(!showColors)}>
            {showColors ? 'Приховати' : 'Колір продукту'}
        </button>
        {showColors && (
            <div className="color-list">
                {colors.map((color, index) => (
                    <div
                        key={`${color.hex}-${color.name}-${index}`}
                        className={`color-square ${selectedColor?.hex === color.hex ? 'selected' : ''}`}
                        onClick={() => {
                            onSelectColor(color.hex, color.name);
                            setSelectedColor(color);
                        }}
                        onMouseEnter={() => {
                            setTooltip({ visible: true, name: color.name, x: 0, y: 0 });
                        }}
                        onMouseLeave={() => {
                            setTooltip({ ...tooltip, visible: false });
                        }}
                        onMouseMove={(e) => {
                            setTooltip({ ...tooltip, x: e.clientX + 10, y: e.clientY + 10 });
                        }}
                        style={{
                            backgroundColor: color.hex,
                        }}
                    />
                ))}
            </div>
        )}
        {tooltip.visible && (
            <div className="tooltip" style={{ left: tooltip.x, top: tooltip.y }}>
                {tooltip.name}
            </div>
        )}
        {selectedColor && (
            <p className='color-title'>
                Вибраний колір: <span style={{ backgroundColor: selectedColor.hex, padding: 4, borderRadius: 5, marginLeft: 10 }}>{selectedColor.name}</span>
            </p>
        )}
    </div>
  );
};

export default ColorPicker;