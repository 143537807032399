import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/orders`);
        setOrders(response.data);
      } catch (error) {
        console.error('Помилка при отриманні замовлень:', error);
      }
    };

    fetchOrders();
  }, []);

  const addOrder = async (order) => {
    try {
      const response = await axios.post(`${baseURL}/api/orders`, order);
      setOrders((prevOrders) => [...prevOrders, response.data]);
    } catch (error) {
      console.error('Помилка при додаванні замовлення:', error);
    }
  };

  return (
    <OrderContext.Provider value={{ orders, addOrder }}>
      {children}
    </OrderContext.Provider>
  );
};