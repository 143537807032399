import React, { useEffect, useState } from 'react';
import axios from 'axios';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function AnalyticsContent() {
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedProducts, setExpandedProducts] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  const [selectedDate, setSelectedDate] = useState('');

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    setSelectedDate(getCurrentDate());
  }, []);
  
  const formatTime = (seconds) => {
    if (seconds == null) return '00:00:00';
    const roundedSeconds = Math.round(seconds);
    const hours = Math.floor(roundedSeconds / 3600);
    const minutes = Math.floor((roundedSeconds % 3600) / 60);
    const secs = roundedSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const fetchProductsAndAnalytics = async () => {
    try {
      const productRes = await axios.get(`${baseURL}/api/products`);
      const productList = productRes.data;
  
      setProducts(productList);
      setFilteredProducts(productList);
  
      const analyticsPromises = productList.map((product) =>
        axios.get(`${baseURL}/api/analytics/${product._id}`)
      );
  
      const visitsPromises = productList.map((product) =>
        axios.get(`${baseURL}/api/analytics/${product._id}/visits`, {
          params: { date: selectedDate }
        })
      );
  
      const analyticsRes = await Promise.allSettled(analyticsPromises);
      const visitsRes = await Promise.allSettled(visitsPromises);
  
      const data = {};
      productList.forEach((product, index) => {
        data[product._id] = {
          analytics: analyticsRes[index].status === 'fulfilled' ? analyticsRes[index].value.data.data : null,
          visits: visitsRes[index].status === 'fulfilled' ? visitsRes[index].value.data.data : [],
        };
      });
  
      setProductData(data);
    } catch (error) {
      console.error('Error fetching products or analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleExpand = (productId) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const filtered = products.filter((product) =>
        product.title.toLowerCase().includes(term)
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredProducts(products);
    setSelectedProduct(null);
  };

  const handleProductSelect = (product) => {
    setSearchTerm('');
    setSelectedProduct(product);
    setFilteredProducts([]);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    fetchProductsAndAnalytics();
  }, [selectedDate]);

  if (loading) return <p>Завантаження аналітики...</p>;

  return (
    <div className='analitic_container'>
      <div className="inner_analitic-container">
        <h3>Аналітика відвідувань сторінок продуктів</h3>
        
        <div className="search_container">
          <div className="search_input-btn">
            <input
              type="text"
              placeholder="Пошук за назвою сторінки"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button onClick={clearSearch} className="clear_button">
              <img src={iconSlider.CloseIcon} alt="x" />
            </button>
          </div>
          {searchTerm && (
            <div className="dropdown_results">
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                  <div
                    key={product._id}
                    className="dropdown_item"
                    onClick={() => handleProductSelect(product)}
                  >
                    <h4>{product.title}</h4>
                  </div>
                ))
              ) : (
                <div className="no_results">
                  <p>Результати не знайдено</p>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="date_filter_container">
          <div className="inner_data-calendar">
            <label htmlFor="dateFilter">Оберіть дату:</label>
            <input
              type="date"
              id="dateFilter"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
        </div>

        {(selectedProduct ? [selectedProduct] : filteredProducts).map((product) => {
          const productInfo = productData[product._id] || {};
          const { analytics, visits } = productInfo;
          const isExpanded = expandedProducts[product._id] || false;

          return (
            <div className='analitic_list' key={product._id}>
              <div className="title_btn-history">
                <h4>{product.title}</h4>
                {analytics ? (
                  <div className="item_data-time__analitic">
                    <p>Кількість переглядів: {productData[product._id]?.visits?.length || 0}</p>
                    <p>Мінімальний час на сторінці: {formatTime(analytics.minTimeOnPage)}</p>
                    <p>Середній час на сторінці: {formatTime(analytics.avgTimeOnPage)}</p>
                    <p>Максимальний час на сторінці: {formatTime(analytics.maxTimeOnPage)}</p>
                  </div>
                ) : (
                  <p>Аналітика для цього продукту недоступна.</p>
                )}
                <button onClick={() => toggleExpand(product._id)}>
                  {isExpanded ? 'Згорнути' : 'Розгорнути'} історію відвідувань
                </button>
                {isExpanded && (
                  <div className='visit_history-analitic'>
                    <h5>Історія відвідувань:</h5>
                    {visits && visits.length > 0 ? (
                      <div className="visit_container">
                        <div className="visit_header">
                          <p className="visit_column">Дата</p>
                          <p className="visit_column">Час перебування</p>
                          <p className="visit_column">Місто</p>
                        </div>
                        {visits.map((visit, index) => (
                          <div key={index} className="visit_row">
                            <p className="visit_column">
                              {new Date(visit.date).toLocaleDateString('uk-UA')}
                            </p>
                            <p className="visit_column">{formatTime(visit.timeOnPage)}</p>
                            <p className="visit_column">{visit.city || 'Невідоме місто'}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Історія відвідувань недоступна.</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AnalyticsContent;