import icon1 from './icon-li/alcohol-1.svg';
import icon2 from './icon-li/alcohol-2.svg';

import icon3 from './icon-li/battery-1.svg';
import icon4 from './icon-li/battery-2.svg';
import icon5 from './icon-li/battery-3.svg';
import icon6 from './icon-li/battery-4.svg';
import icon7 from './icon-li/battery-5.svg';
import icon8 from './icon-li/battery-6.svg';

import icon15 from './icon-li/beauty-1.svg';
import icon16 from './icon-li/beauty-2.svg';
import icon17 from './icon-li/beauty-3.svg';
import icon18 from './icon-li/beauty-4.svg';

import icon19 from './icon-li/case-1.svg';
import icon20 from './icon-li/case-2.svg';
import icon21 from './icon-li/case-3.svg';
import icon22 from './icon-li/case-4.svg';

import icon23 from './icon-li/cat-1.svg';
import icon24 from './icon-li/cat-2.svg';
import icon25 from './icon-li/cat-3.svg';

import icon26 from './icon-li/chair-1.svg';
import icon27 from './icon-li/chair-2.svg';
import icon28 from './icon-li/chair-3.svg';
import icon29 from './icon-li/chair-4.svg';
import icon30 from './icon-li/chair-5.svg';
import icon31 from './icon-li/chair-6.svg';
import icon32 from './icon-li/chair-7.svg';
import icon33 from './icon-li/chair-8.svg';

import icon47 from './icon-li/clothing-1.svg';
import icon48 from './icon-li/clothing-2.svg';
import icon49 from './icon-li/clothing-3.svg';
import icon50 from './icon-li/clothing-4.svg';
import icon51 from './icon-li/clothing-5.svg';
import icon52 from './icon-li/clothing-6.svg';
import icon53 from './icon-li/clothing-7.svg';
import icon54 from './icon-li/clothing-8.svg';

import icon59 from './icon-li/dog-1.svg';
import icon60 from './icon-li/dog-2.svg';
import icon61 from './icon-li/dog-3.svg';

import icon62 from './icon-li/food-1.svg';
import icon63 from './icon-li/food-2.svg';
import icon64 from './icon-li/food-3.svg';
import icon65 from './icon-li/food-4.svg';
import icon66 from './icon-li/food-5.svg';
import icon67 from './icon-li/food-6.svg';

import icon68 from './icon-li/gamepad-1.svg';
import icon69 from './icon-li/gamepad-2.svg';
import icon70 from './icon-li/gamepad-3.svg';
import icon71 from './icon-li/gamepad-4.svg';
import icon72 from './icon-li/gamepad-5.svg';
import icon73 from './icon-li/gamepad-6.svg';

import icon74 from './icon-li/garden-1.svg';
import icon75 from './icon-li/garden-2.svg';
import icon76 from './icon-li/garden-3.svg';
import icon77 from './icon-li/garden-4.svg';
import icon78 from './icon-li/garden-5.svg';
import icon79 from './icon-li/garden-6.svg';

import icon80 from './icon-li/headphone-1.svg';
import icon81 from './icon-li/headphone-2.svg';
import icon82 from './icon-li/headphone-3.svg';
import icon83 from './icon-li/headphone-4.svg';
import icon84 from './icon-li/headphone-5.svg';

import icon85 from './icon-li/him-1.svg';
import icon86 from './icon-li/him-2.svg';
import icon87 from './icon-li/him-3.svg';

import icon88 from './icon-li/home-1.svg';
import icon89 from './icon-li/home-2.svg';
import icon90 from './icon-li/home-3.svg';
import icon91 from './icon-li/home-4.svg';

import icon92 from './icon-li/keyboard-1.svg';
import icon93 from './icon-li/keyboard-2.svg';
import icon94 from './icon-li/keyboard-3.svg';
import icon95 from './icon-li/keyboard-4.svg';
import icon96 from './icon-li/keyboard-5.svg';

import icon97 from './icon-li/kids-1.svg';
import icon98 from './icon-li/kids-2.svg';
import icon99 from './icon-li/kids-3.svg';
import icon100 from './icon-li/kids-4.svg';
import icon101 from './icon-li/kids-5.svg';
import icon102 from './icon-li/kids-6.svg';
import icon103 from './icon-li/kids-7.svg';
import icon104 from './icon-li/kids-8.svg';

import icon105 from './icon-li/laptop-1.svg';
import icon106 from './icon-li/laptop-2.svg';
import icon107 from './icon-li/laptop-3.svg';
import icon108 from './icon-li/laptop-4.svg';

import icon109 from './icon-li/mobile-1.svg';
import icon110 from './icon-li/mobile-2.svg';
import icon111 from './icon-li/mobile-3.svg';
import icon112 from './icon-li/mobile-4.svg';

import icon113 from './icon-li/mouse-1.svg';
import icon114 from './icon-li/mouse-2.svg';
import icon115 from './icon-li/mouse-3.svg';

import icon116 from './icon-li/sandals-1.svg';
import icon117 from './icon-li/sandals-2.svg';
import icon118 from './icon-li/sandals-3.svg';
import icon119 from './icon-li/sandals-4.svg';

import icon120 from './icon-li/sport-1.svg';
import icon121 from './icon-li/sport-2.svg';
import icon122 from './icon-li/sport-3.svg';
import icon123 from './icon-li/sport-4.svg';
import icon124 from './icon-li/sport-5.svg';
import icon125 from './icon-li/sport-6.svg';
import icon126 from './icon-li/sport-7.svg';
import icon127 from './icon-li/sport-8.svg';
import icon128 from './icon-li/sport-9.svg';
import icon129 from './icon-li/sport-10.svg';
import icon130 from './icon-li/sport-11.svg';

import icon131 from './icon-li/tap-1.svg';
import icon132 from './icon-li/tap-1.svg';
import icon133 from './icon-li/tap-2.svg';

import icon134 from './icon-li/tool-1.svg';
import icon135 from './icon-li/tool-2.svg';
import icon136 from './icon-li/tool-3.svg';
import icon137 from './icon-li/tool-4.svg';
import icon138 from './icon-li/tool-5.svg';
import icon139 from './icon-li/tool-6.svg';
import icon140 from './icon-li/tool-7.svg';
import icon141 from './icon-li/tool-8.svg';
import icon142 from './icon-li/tool-9.svg';
import icon143 from './icon-li/tool-10.svg';
import icon144 from './icon-li/tool-11.svg';

import icon145 from './icon-li/tree-1.svg';
import icon146 from './icon-li/tree-2.svg';
import icon147 from './icon-li/tree-3.svg';
import icon148 from './icon-li/tree-4.svg';
import icon149 from './icon-li/tree-5.svg';
import icon150 from './icon-li/tree-6.svg';
import icon151 from './icon-li/tree-7.svg';

import icon152 from './icon-li/tv-1.svg';
import icon153 from './icon-li/tv-2.svg';
import icon154 from './icon-li/tv-3.svg';
import icon155 from './icon-li/tv-4.svg';

import icon156 from './icon-li/washing-machine-1.svg';
import icon157 from './icon-li/washing-machine-2.svg';
import icon158 from './icon-li/washing-machine-3.svg';
import icon159 from './icon-li/washing-machine-4.svg';
import icon160 from './icon-li/washing-machine-5.svg';



const iconLi = {
    icon1, icon2, icon3, icon4,icon5,icon6,icon7,icon8,
    icon15,icon16,icon17,icon18,icon19,icon20,icon21,
    icon22,icon23,icon24,icon25,icon26,icon27,icon28,
    icon29,icon30,icon31,icon32,icon33,icon47,icon48,
    icon49,icon50,icon51,icon52,icon53,icon54,icon59,
    icon60,icon61,icon62,icon63,icon64,icon65,icon66,
    icon67,icon68,icon69,icon70,icon71,icon72,icon73,
    icon74,icon75,icon76,icon77,icon78,icon79,icon80,
    icon81,icon82,icon83,icon84,icon85,icon86,icon87,
    icon88,icon89,icon90,icon91,icon92,icon93,icon94,
    icon95,icon96,icon97,icon98,icon99,icon100,icon101,
    icon102,icon103,icon104,icon105,icon106,icon107,icon108,
    icon109,icon110,icon111,
    icon112,icon113,icon114,icon115,icon116,icon117,icon118,
    icon119,icon120,icon121,icon122,icon123,icon124,icon125,
    icon126,icon127,icon128,icon129,icon130,icon131,icon132,
    icon133,icon134,icon135,icon136,icon137,icon138,icon139,
    icon140,icon141,icon142,icon143,icon144,icon145,icon146,
    icon147,icon148,icon149,icon150,icon151,icon152,icon153,
    icon154,icon155,icon156,icon157,icon158,icon159,icon160
  };
  
export default iconLi;