import ArrowLeft from './left.svg'
import ArrowRight from './right.svg'
import PlusIcon from './plus-icon.svg'
import WishIcon from './heart-icon.svg'
import DwnIcon from './dwn-icon.svg'
import CloseIcon from './icon-close.svg'
import Minus from './minus.svg'
import Plus from './plus.svg'
import DeleteIcon from './trash.svg'
import MarkerIcon from './marker.svg'
import OpenIcon from './open-list.svg'
import DelIcon from './delivery.svg'
import CardBag from './bag-white.svg'
import RevIcon from './review-icon.svg'
import FilterIcon from './filter-icon.svg'
import PenIcon from './pen-line.svg'

const iconSlider = {
    ArrowLeft,
    ArrowRight,
    PlusIcon,
    WishIcon,
    DwnIcon,
    CloseIcon,
    Minus,
    Plus,
    DeleteIcon,
    MarkerIcon,
    OpenIcon,
    DelIcon,
    CardBag,
    RevIcon,
    FilterIcon,
    PenIcon
  };
  
  export default iconSlider;