import React from 'react';
import { useCart } from '../../CartContext';
import { useNavigate } from 'react-router-dom';

const formatNumber = (number) => {
  return number.toLocaleString('uk-UA');
};

function CartHero() {
  const { cartItems, toggleCart } = useCart();
  const navigate = useNavigate();

  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce(
    (total, item) => total + parseInt((item.price || '').toString().replace(/\D/g, '')) * item.quantity,
    0
  );

  if (cartItems.length === 0) {
    return null;
  }

  const handleOrderClick = () => {
    navigate('/checkout');
  };

  return (
    <div className="cart-summary">
      <p>У кошику {totalItems} {totalItems === 1 ? 'замовлення' : 'замовлень'} <br /> на суму {formatNumber(totalPrice)} грн.</p>
      <div className="cart-summary-buttons">
        <button className='open_bag' onClick={toggleCart}>До кошика</button>
        <button className='open_order' onClick={handleOrderClick}>Купити зараз</button>
      </div>
    </div>
  );
}

export default CartHero;