import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import icon from '../../icon/icon';
import SearchPanel from '../search/SearchPage';
import './nav.css';

import Bag from '../bag/Bag';

function NavPanel() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/cabinet');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className='nav_container'>
        <div className="nav_item">
            <div className="inner_nav">
                <div className="border_nav">
                    <Link className='logo' to='/'>STORE</Link>
                    <div className="nav_btn-item">
                        <Link className='catalog_link' to='/catalog-tovariv'>Каталог<img src={icon.FolderIconW} /></Link>
                        <Link className='catalog_link-m' to='/catalog-tovariv'><img src={icon.FolderIcon} /></Link>
                        <SearchPanel />
                        <button className="cabinet_btn" onClick={handleLoginClick}><img src={icon.PersonIcon} alt="Cabinet" /></button>
                        <Bag />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default NavPanel;