import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useCart } from '../../CartContext';
import { OrderContext } from '../../OrderContext';
import NavPanel from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer'
import './order.css';

function OrderConfirmation() {
  const location = useLocation();
  const { formData, cartItems, totalPrice } = location.state || {};
  const { clearCart } = useCart();
  const { addOrder } = useContext(OrderContext);

  const [orderProcessed, setOrderProcessed] = useState(false);

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const deliveryMethodOptions = {
    novaPostDepartment: 'Нова Пошта - відділення',
    novaPostPostamat: 'Нова Пошта - поштомат',
    novaPostCourier: 'Нова Пошта - кур\'єр',
    ukrPost: 'Укр Пошта',
  };

  useEffect(() => {
    if (formData && cartItems && typeof totalPrice !== 'undefined' && !orderProcessed) {
      const newOrder = {
        date: new Date().toLocaleString(),
        orderNumber: Date.now(),
        user: { ...formData, department: formData.department },
        items: cartItems,
        total: totalPrice,
      };
  
      setOrderProcessed(true);
      clearCart();
      addOrder(newOrder);
    }
  }, [formData, cartItems, totalPrice, orderProcessed, clearCart, addOrder]);

  if (!formData || !cartItems || typeof totalPrice === 'undefined') {
    return <div>Дані про замовлення відсутні. Поверніться на сторінку оформлення замовлення.</div>;
  }

  return (
    <div className='main-container'>
      <NavPanel />
      <div className="container">
        <div className="product_container">
          <div className="inner_product">
            <div className="order-confirmation">
              <h2>Ваше замовлення підтверджено!</h2>
              <h3>Ваші дані</h3>
              <p><b>Ім'я:</b> {formData.firstName} {formData.lastName}</p>
              <p><b>Телефон:</b> {formData.phone}</p>

              <h3>Дані про доставку</h3>
              <p><b>Спосіб доставки:</b> {deliveryMethodOptions[formData.deliveryMethod] || formData.deliveryMethod}</p>
              <p><b>Місто:</b> {formData.city}</p>
              {formData.department && <p><b>Відділення:</b> {formData.department}</p>}
              {formData.street && (
                <>
                  <p><b>Область:</b> {formData.region}</p>
                  <p><b>Район:</b> {formData.district}</p>
                  <p><b>Вулиця:</b> {formData.street}</p>
                  <p><b>Будинок №:</b> {formData.buildingNumber}</p>
                </>
              )}

              <h3>Спосіб оплати</h3>
              <p>{formData.paymentMethod}</p>

              <h3>Деталі замовлення</h3>
              <ul className="order-items">
                {cartItems.map((item, index) => (
                  <li key={index} className="order-item">
                    <p><b>Продукт:</b> {item.title}</p>
                    <p><b>Кількість:</b> {item.quantity}</p>
                    <p><b>Ціна:</b> {formatPrice(item.price)} грн</p>
                  </li>
                ))}
              </ul>

              <h3>Загальна сума:</h3>
              <p>{formatPrice(totalPrice)} грн</p>
            </div>
          </div>
        </div>
        <Footer />         
      </div>
    </div>
  );
}

export default OrderConfirmation;