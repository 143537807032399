import sIcon1 from './f.svg'
import sIcon2 from './i.svg'
import sIcon3 from './t.svg'
import sIcon4 from './tt.svg'
import sIcon5 from './p.svg'


const iconFooter = {
    sIcon1,
    sIcon2,
    sIcon3,
    sIcon4,
    sIcon5
};

export default iconFooter;