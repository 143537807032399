import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function CreateCategoryPage() {
  const [cards, setCards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [notification, setNotification] = useState(null);

  const fetchCards = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/cards`);
      setCards(response.data);
    } catch (error) {
      console.error('Error fetching cards:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCards();
    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedCardData = cards.find(card => card._id === selectedCard);
    const title = selectedCardData ? selectedCardData.text : '';

    try {
      const response = await fetch(`${baseURL}/api/categories`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ title }),
      });

      const data = await response.json();
      if (response.ok) {
        showNotification('Сторінку успішно створено!', 'success');

        if (selectedCard) {
          await axios.put(`${baseURL}/api/cards/${selectedCard}`, {
            categoryId: data._id,
          });
          showNotification('Картка оновлена з новою категорією!', 'success');
        }

        fetchCategories();
      } else {
        showNotification(`Помилка: ${data.message}`, 'error');
      }
    } catch (error) {
      console.error('Error creating category page:', error);
      showNotification('Сталася помилка при створенні категорії.', 'error');
    }
  };

  const handleDelete = async () => {
    if (!selectedCategory) {
      showNotification('Будь ласка, виберіть категорію для видалення', 'error');
      return;
    }

    try {
      const response = await fetch(`${baseURL}/api/categories/${selectedCategory}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        showNotification('Категорію успішно видалено!', 'success');
        setSelectedCategory('');
        fetchCategories();
      } else {
        const data = await response.json();
        showNotification(`Помилка: ${data.message}`, 'error');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
      showNotification('Сталася помилка при видаленні категорії.', 'error');
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className='create_container'>
      <div className="inner_create">
        <div className='create'>
          <p className='create_title'>Створити сторінку каталогу</p>
          <form className="create_form" onSubmit={handleSubmit}>
            <span>Оберіть категорію</span>
            <select
              value={selectedCard}
              onChange={(e) => setSelectedCard(e.target.value)}
              required
            >
              <option value="">Категорії каталогу</option>
              {cards.map((card) => (
                <option key={card._id} value={card._id}>
                  {card.text}
                </option>
              ))}
            </select>

            <button className='create_btn' type="submit">Створити сторінку</button>

            <span>Видалити сторінку категорії</span>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Виберіть категорію для видалення</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.title}
                </option>
              ))}
            </select>

            <button className='delete-btn' type="button" onClick={handleDelete}>Видалити</button>
          </form>

          {notification && (
            <div className={`notification ${notification.type}`}>
              {notification.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateCategoryPage;