import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function AllReviews() {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllReviews = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/api/reviews/all`);
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setReviews(sortedData);
    } catch (error) {
      console.error('Помилка завантаження відгуків:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllReviews();
  }, []);

  const formatDateTime = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('uk-UA', options);
  };

  return (
    <div className="all-reviews-container">
      <div className="rev_title-all">
        <div className="inner_rev-all">
          <h2>Усі відгуки</h2>
        </div>
      </div>
      {isLoading ? (
        <p>Завантаження...</p>
      ) : (
        <div className="reviews-list">
          {reviews.map((review, index) => (
            <div key={index} className="review-item">
              <div className="inner-review-item">
                <Link target='blank' to={`/prodykt/${review.productId._id}`} className="product-link_rev">Коментар на сторінці "{review.productId.title}"</Link>
                <span className="data-review">Дата публікації: {formatDateTime(review.createdAt)}</span>
                <p className="review-text">{review.feedback}</p>
                <p className="review-descr">Переваги: {review.pros}</p>
                <p>Недоліки: {review.cons}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AllReviews;