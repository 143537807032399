import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NavPanel from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer'
import './catalog.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function CatalogPage() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const res = await axios.get(`${baseURL}/api/cards`);
        setCards(res.data);
      } catch (error) {
        console.error('Помилка при завантаженні карток:', error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div className="main-container">
      <NavPanel />
      <div className="container">
        <div className="catalog_container">
          <div className="inner_catalog">
            <h2 className="catalog_title">Каталог</h2>
            <div className="catalog_grid">
              {cards.map((card) => (
                <Link key={card._id} className="catalog_card" to={`/katehoriya-tovaru/${card.categoryId}`}>
                  <img src={card.imageUrl} alt={card.text} />
                  <p>{card.text}</p>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CatalogPage;