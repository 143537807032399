import React, { useEffect, useState, useContext } from 'react';
import { SocketContext } from '../../../SocketContext';

const UserCountReg = () => {
  const [userCount, setUserCount] = useState(0);
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const fetchInitialCount = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/user/count`);
        const data = await response.json();
        setUserCount(data.count);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchInitialCount();

    if (socket) {
      socket.on('updateUserCount', (newCount) => {
        setUserCount(newCount);
      });
    }

    return () => {
      if (socket) {
        socket.off('updateUserCount');
      }
    };
  }, [socket]);

  return (
    <div className="online-user-item">
      <div className="inner_online">
        <h3>Зареєстрованих користувачів: {userCount}</h3>
      </div>
    </div>
  );
};

export default UserCountReg;