import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './catalog.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function MenuCategories() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/cards`);
        setCards(response.data);
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div className="categories_item">
      <div className="menu_categories">
        <ul>
          {cards.map((card) => (
            <li key={card._id}>
              <Link className="catalog_link-li" to={`/katehoriya-tovaru/${card.categoryId}`}>
                <img src={card.icon} alt="icon" />
                <p>{card.text}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MenuCategories;