import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ColorPicker from '../ColorPicker';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function ProductCreatePage() {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [color, setColor] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [characteristics, setCharacteristics] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [hiddenCharacteristics, setHiddenCharacteristics] = useState([]);

  const [promo, setPromo] = useState('Без промо');
  const [oldPrice, setOldPrice] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/catalog-cards`);
        setCategories(response.data);
      } catch (error) {
        console.error('Помилка при завантаженні категорій:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleImageUpload = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', 'ml_default');

    try {
      const res = await axios.post(
        'https://api.cloudinary.com/v1_1/dca5poo9m/image/upload',
        formData
      );
      return res.data.secure_url;
    } catch (error) {
      console.error('Помилка завантаження зображення:', error);
      return '';
    }
  };

  const handleImagesChange = async (e) => {
    const files = Array.from(e.target.files);
    setIsUploading(true);
    setFileNames(files.map(file => file.name));

    const urls = await Promise.all(files.map((file) => handleImageUpload(file)));
    setUploadedImages(urls.filter(url => url !== ''));
    setIsUploading(false);
  };

  const addCharacteristic = () => {
    setCharacteristics([...characteristics, { name: '', value: '' }]);
  };

  const handleCharacteristicChange = (index, field, value) => {
    const updatedCharacteristics = [...characteristics];
    updatedCharacteristics[index][field] = value;
    setCharacteristics(updatedCharacteristics);
  };

  const removeCharacteristic = (index) => {
    setCharacteristics(characteristics.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}/api/products`, {
        title,
        price,
        oldPrice: promo === 'Акція' ? oldPrice : null,
        description,
        images: uploadedImages,
        color,
        category: selectedCategory,
        characteristics,
        hiddenCharacteristics,
        promo,
      });
      showNotification('Продукт успішно створено!', 'success');
      setTitle('');
      setPrice('');
      setDescription('');
      setUploadedImages([]);
      setColor('');
      setSelectedCategory('');
      setFileNames([]);
      setCharacteristics([]);
      setHiddenCharacteristics([]);
    } catch (error) {
      console.error('Помилка при створенні продукту:', error);
      showNotification('Помилка при створенні продукту, спробуйте ще раз.', 'error');
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className='create_container'>
      <div className="inner_create">
        <div className="create">
          <h2 className='create_title'>Створити новий продукт</h2>
          <form className="create_form" onSubmit={handleSubmit}>
            <span>Підкатегорія:</span>
              <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} required>
                <option value="">Оберіть підкатегорію</option>
                {categories.map(category => (
                  <option key={category._id} value={category._id}>{category.title}</option>
                ))}
              </select>
              <span>Промо:</span>
              <select
                value={promo}
                onChange={(e) => {
                  setPromo(e.target.value);
                  if (e.target.value !== 'Акція') {
                    setOldPrice('');
                  }
                }}
                required
              >
                <option value="Без промо">Без промо</option>
                <option value="Хіт продажу">Хіт продажу</option>
                <option value="Топ продажу">Топ продажу</option>
                <option value="Новинка">Новинка</option>
                <option value="Акція">Акція</option>
              </select>

              {promo === 'Акція' && (
                <>
                  <span>Стара ціна:</span>
                  <input
                    type="text"
                    value={oldPrice}
                    onChange={(e) => setOldPrice(e.target.value)}
                    required
                  />
                </>
              )}
              <span>Назва продукту:</span>
              <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
              <span>Ціна:</span>
              <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
              <span>Опис продукту:</span>
              <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
              <div className="custom-file-upload">
                <input
                  type="file"
                  multiple
                  onChange={handleImagesChange}
                  id="file-upload"
                  style={{ display: 'none' }}
                  required
                />
                <label className='file-upload' htmlFor="file-upload">
                  <img
                    src={iconSlider.DwnIcon}
                    alt="Upload Icon"
                    className="upload-preview"
                  />
                  <p>
                    {isUploading 
                      ? 'Завантаження...' 
                      : (uploadedImages.length > 0 
                        ? `Завантажено картинок ${uploadedImages.length}` 
                        : 'Завантажити картинки')}
                  </p>
                </label>
              </div>

            {uploadedImages.length > 0 && (
              <div className="uploaded-images">
                {uploadedImages.map((url, index) => (
                  <img key={index} src={url} alt={`Зображення ${index + 1}`} />
                ))}
              </div>
            )}

            <ColorPicker onSelectColor={setColor} />

            <div className="characteristics-section">
              <h3 className='create_title'>Характеристики</h3>
              {characteristics.map((char, index) => (
                <div key={index} className="characteristic-inputs">
                  <div className="input_container">
                    <input
                      type="text"
                      placeholder="Назва (Наприклад: колір)"
                      value={char.name}
                      onChange={(e) => handleCharacteristicChange(index, 'name', e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      placeholder="Опис (Наприклад: синій)"
                      value={char.value}
                      onChange={(e) => handleCharacteristicChange(index, 'value', e.target.value)}
                      required
                    />
                    <button type="button" className="remove-char-btn" onClick={() => removeCharacteristic(index)}><img src={iconSlider.DeleteIcon} /></button>
                  </div>
                </div>
              ))}
              <button className='char_btn-plus' type="button" onClick={addCharacteristic}>Додати характеристики</button>
            </div>
            
            <button className='create_page-btn' type="submit">Створити продукт</button>
          </form>

          {notification && (
            <div className={`notification ${notification.type}`}>
              {notification.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCreatePage;