import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function DeleteProduct() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/products`);
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error('Помилка завантаження продуктів:', error);
        setNotification({ message: 'Не вдалося завантажити список продуктів.', type: 'error' });
      }
    };
    fetchProducts();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered = products.filter((product) =>
      product.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProducts(filtered);
    setIsDropdownOpen(true);
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setIsDropdownOpen(false);
  };

  const handleDeleteProduct = async () => {
    if (!selectedProduct) return;

    try {
      await axios.delete(`${baseURL}/api/products/${selectedProduct._id}`);
      setNotification({ message: `Продукт "${selectedProduct.title}" успішно видалено!`, type: 'success' });

      const updatedProducts = products.filter((p) => p._id !== selectedProduct._id);
      setProducts(updatedProducts);
      setFilteredProducts(updatedProducts);
      setSelectedProduct(null);
      setSearchQuery('');
    } catch (error) {
      console.error('Помилка при видаленні продукту:', error);
      setNotification({ message: 'Не вдалося видалити продукт. Спробуйте ще раз.', type: 'error' });
    }
  };

  return (
    <div className='delete_product'>
      <div className="inner_delete">
        <div className="product-search" ref={dropdownRef}>
          <input
            type="text"
            placeholder="Пошук продукту за назвою..."
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={() => setIsDropdownOpen(true)}
          />
          {isDropdownOpen && (
            <ul className="product-list">
              {filteredProducts.map((product) => (
                <li
                  key={product._id}
                  onClick={() => handleProductSelect(product)}
                  style={{ cursor: 'pointer', padding: '5px 0' }}
                >
                  {product.title}
                </li>
              ))}
            </ul>
          )}
        </div>
        {selectedProduct && (
          <div className='delete_container'>
            <h3>Вибрана сторінка: {selectedProduct.title}</h3>
            <button className="delete_page-btn" onClick={handleDeleteProduct}>Видалити продукт</button>
          </div>
        )}
        {notification && <div className={`notification ${notification.type}`}>{notification.message}</div>}
      </div>
    </div>
  );
}

export default DeleteProduct;