import React, { useState } from 'react';
import ProductCreatePage from './ProductCreatePage';
import ProductEditForm from './ProductEditForm';
import DeleteProduct from './ProductDeleteForm';

const ProductTab = () => {
  const [selectedTab, setSelectedTab] = useState('create');

  return (
    <div className="slider-prod-manager">
      <div className="inner_card-manager">
        <p className='slide_title'>Сторінка продукту</p>
        <div className="tabs_line">
          <button className={`tab_btn ${selectedTab === 'create' ? 'active' : ''}`} onClick={() => setSelectedTab('create')}>Створити сторінку продукту</button>
          <button className={`tab_btn ${selectedTab === 'edit' ? 'active' : ''}`} onClick={() => setSelectedTab('edit')}>Редагувати сторінку продукту</button>
          <button className={`tab_btn ${selectedTab === 'delete' ? 'active' : ''}`} onClick={() => setSelectedTab('delete')}>Видалити сторінку продукту</button>
        </div>
        
        <div className="content_slider-create">
          {selectedTab === 'create' && <ProductCreatePage />}
          {selectedTab === 'edit' && <ProductEditForm />}
          {selectedTab === 'delete' && <DeleteProduct />}
        </div>
      </div>
    </div>
  );
};

export default ProductTab;