import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import L from 'leaflet';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const MapComponent = () => {
  const [userLocations, setUserLocations] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseURL}/api/user-city`)
      .then((response) => {
        setUserLocations(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user locations:', error);
      });
  }, []);

  const createIcon = (iconPath) => {
    return new L.Icon({
      iconUrl: iconPath,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });
  };

  return (
    <MapContainer 
      center={[50.4501, 30.5234]}
      zoom={6} 
      style={{ height: '500px', width: '700px', borderRadius: '10px', border: '1px solid #121113' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {userLocations.map((user, index) => {
        const userIcon = createIcon(iconSlider.MarkerIcon);

        return (
          <Marker 
            key={index} 
            position={[user.latitude, user.longitude]} 
            icon={userIcon}
          >
            <Popup>
              {user.city} - {user.visitedAt}
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default MapComponent;