import React, { useEffect, useState } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CityVisits = () => {
  const [cityVisits, setCityVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCityVisits = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/city-visits`);
        setCityVisits(response.data);
      } catch (err) {
        setError('Failed to fetch city visit data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCityVisits();
  }, []);

  if (loading) {
    return <p className='loading_data'>Завантаження...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!cityVisits || cityVisits.length === 0) {
    return <p className='loading_data'>Оновлення статистики (кожна нова година після поточної)</p>;
  }

  return (
    <div className='city_container'>
      <h2>Відвідування по містах</h2>
      <ul className='hidden_container'>
        {cityVisits.map((cityVisit, index) => (
          <li key={index}>
            {cityVisit.city}: {cityVisit.visitorsCount} - Відвідувань
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CityVisits;