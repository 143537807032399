import React from 'react';
import NavPanel from '../components/nav/Nav'
import Footer from '../components/footer/Footer'

function PrivacyPolicy() {
  return (
    <div className='main-container'>
        <NavPanel />
        <div className="container">
          <div className="user_agreement-container">
            <div className="inner_agreement-container">
              <h1>Політика конфіденційності та положення про обробку та захист персональних даних</h1>

              <span>
                1. Дане положення запроваджене керівництвом магазину, з метою врегулювання взаємовідносин щодо збору, зберігання, обробки використання та розкриття персональних даних, які виникають при здійсненні статутної діяльності компанії, в тому числі з користувачами засобів електронної комерції які використовує компанія в своїй діяльності. 
              </span><br />
              <span>
                Дане положення розповсюджується на засоби здійснення електронної комерції магазину. Дане положення обов’язкове для дотримання відповідальними особами та співробітниками компанії, які здійснюють безпосередній збір та обробку персональних даних або мають доступ до них. 
              </span><br />
              <span>
                База персональних даних користувачів зберігається та опрацьовується на відповідному, захищеному сервері. 
              </span><br />
              <span>
                2. Будь-яка особа, що відвідує сайт або в будь-який інший спосіб використовує його, незалежно від того в чиїх інтересах нею вчиняються такі дії, автоматично визнається Користувачем в контексті умов даного положення (надалі по тексту - користувач). 
              </span><br />
              <span>
                3. Здійснивши реєстрацію на сайті магазину, або починаючи використання/відвідання сайту, або при спробі оформити замовлення без попередньої реєстрації, Користувач автоматично надає дозвіл та однозначну згоду на обробку його персональних даних на умовах та в порядку, викладених нижче, а також підтверджує ознайомлення з цим Положенням, його прийняття та згоду з його змістом.
              </span><br />
              <span>
                4. В даному положенні всі терміни наведені в розумінні та тлумаченні викладеному в Законі України «Про захист персональних даних». 
              </span><br />
              <span>
                5. На ресурсах компанії можуть розміщуватись посилання на сторонні сайти/ресурси що не належать компанії. При переході по такому посиланню, користувачу рекомендується ознайомитись із правилами обробки персональних даних такого ресурсу, адже дане положення не розповсюджується на такі сторонні ресурси.
              </span><br />
              <span>
                6. Персональні дані, в контексті даного положення це будь-яка інформація що будь-яким чином, прямо чи опосередковано стосується особи користувача. Такими даними зокрема, але не обмежуючись, можуть бути:<br /><br />
                 - Прізвище, ім’я, по батькові користувача, <br />
                 - Номери телефонів користувача, <br />
                 - Адреси електронної пошти, <br />
                 - Поштові адреси зокрема адреси доставки/  перебування/ роботи/ місцезнаходження/геолокації тощо.  <br />
                 - Відомості про пристрої користувача (тип, браузер, ОС) ІР адреса користувача, тощо. <br />
                 - Відгуки, коментарі користувача, історії переписок із адміністрацією ресурсів компанії, а також відомості отриманні під час усного спілкування із співробітниками компанії. <br />
                 - Відомості які надає користувач під час самостійно або автоматичного внесення їх у відповідні форми ресурсів компанії (наприклад при заповненні власного профілю, анкет, опитувань тощо)<br />
                 - Відомості що надаються користувачем під час здійснення оплати на ресурсах компанії, в тому числі під час придбання товарів/послуг як за власні кошти так із використанням кредитних продуктів, розстрочки, оплати частинами тощо. В такому випадку користувач може надавати паспортні дані, ідентифікаційний код та інші відомості що можуть знадобитися кредитній/фінансовій установі для успішного оформлення купівлі-продажу на ресурсах компанії. <br />
                 - Відомості отримані компанією на законних підставах від третіх осіб, а також інформація отримана під час реєстрації за допомогою служб аутентифікації соціальних мереж. В такому випадку до переліку бази персональних даних користувача попадають також відомості надані третіми особами, або службами з відповідних облікових записів (профілів/акаунтів) таких соціальних мереж. <br />
              </span><br />
              <span>
                Перелічені в даному пункті положення персональні дані є не вичерпними і не обов’язковим в повному обсязі для всіх користувачів. Даний перелік може змінюватися в залежності від конкретних обставин або мети яку переслідує користувач, його потреб/побажань, та дій виконуваних користувачем на ресурсах компанії.
              </span><br />
              <span>
                7. Метою збору, зберігання, обробки використання персональних даних магазином є:<br /><br />
                - ідентифікація особи як користувача ресурсів компанії, надання користувачу можливості використання ресурсів компанії в залежності від його потреб; <br />
                - належного виконання компанією положень щодо продажу товарів та послуг; <br />
                - забезпечення можливості здійснення замовлення, розрахунку отримання інших можливих благ та послуг на ресурсах компанії;<br />
                - забезпечення можливості отримання інформації від компанії, зокрема шляхом реалізації бонусних програм, програм лояльності, отримання пропозиції від компанії у вигляді розсилок поштою, смс повідомлень, повідомлень месенджерів тощо. <br />
                - забезпечення можливості покращення ресурсів компанії зокрема шляхом проведення добровільних опитувань, досліджень та фіксації інтересів користувачів до певних товарів, створення рейтингів та аналізу вподобань користувачів. <br />
                - надання користувачам персональних знижок, промокодів та пропозицій.<br />
                - надання користувачам новин компанії, а також інформації щодо товарів та послуг компанії.<br />
                - проведення дослідницької та аналітичної діяльності на підставі отриманих відомостей. <br />
                - ведення господарської діяльності за видами діяльності та цілями згідно статуту компанії;<br />
                - забезпечення реалізації податкового, бухгалтерського, статистичного та іншого обліку, звітності, аудиту, тощо;<br />
                - будь-які інші відносини, що виникають із користувачами та вимагають обробки персональних даних.<br /><br />
                Обробка персональних даних здійснюється на виконання та згідно вимог чинного законодавства України. 
              </span><br />
              <span>
                8. Строки обробки та зберігання персональних даних користувачів встановлюються в залежності від мети збору та оброки таких даних, а також випливають із умов договорів, укладених з користувачами у відповідності до вимог діючого законодавства України. Персональні дані обробляються та зберігаються настільки довго, наскільки це необхідно для досягнення цілей, заради яких вони були зібрані, в тому числі для виконання будь-яких юридичних, бухгалтерських вимог, або вимог щодо звітності або до моменту видалення даних користувачем або за запитом користувача.
              </span><br />
              <span>
                9. Користувач в будь-який момент вправі відкликати свою згоду на обробку та збір персональних даних. Також користувач вправі будь-якої миті змінити та/або видалити раніше надану персональну (особисту) інформацію. <br /><br />
                Такі зміни можна ініціювати у наступний спосіб: Самостійно змінити дані в особистому кабінеті користувача, на сайті або в додатку або звернутися за телефоном гарячої лінії та повідомити про своє бажання змінити або видалити раніше надану персональну інформацію. <br /><br />
                Видалення облікового запису/особистого кабінету користувача за його ініціативою здійснюється в порядку визначеному в угоді користувача. <br /><br />
                Компанія залишає за собою право на власний розсуд видалити обліковий запис, зокрема, у випадках відсутності активності користувача строком більше шести місяців. Можуть бути видалені як обліковий запис так персональні (особисті) дані, що зберігаються в ньому. Після видалення облікового запису користувач втрачає доступ та можливість користуватись таким обліковим записом. <br /><br />
              </span><br />
              <span>
                10. Надання (розкриття) персональних даних третім особам може здійснюватися за наступних обставин. <br /><br />
                За обґрунтованими запитами правоохоронних, контролюючих органів, а також інших органів державної влади, якщо згідно законодавства їх наділено повноваженнями витребувати такі дані. <br /><br />
                З метою запобігання та боротьби із випадками шахрайства та/або зловживання на ресурсах компанії.<br /><br />
                Для виконання замовлень компанія вправі залучати сторонніх постачальників товарів та/або послуг, (наприклад послуг доставки/ремонту). За таких обставин сторонні постачальники отримують доступ та використовують персональні дані користувача в межах необхідних для належного виконання замовлень користувача.<br /><br />
                фінансові/кредитні установи отримують доступ до персональних даних користувачів у випадках оформлення ними покупки товарів або послуг із використанням доступних банківських/кредитних продуктів, в обсягах необхідних для реалізації такого запиту користувача. Такі персональні дані обробляються також на підставі відповідних положень щодо персональних даних що діють в такій фінансовій\кредитній установі. <br /><br />
                постачальники та партнери компанії можуть отримати персональні дані користувачів в рамках проведення маркетингових заходів, таких як конкурси, акції, нагородження переможців, тощо. Також, персональні дані можуть бути надані партнерам/постачальникам компанії у випадках необхідності проведення аналітичної та дослідницької діяльності покликаної покращити точність пропозиції для користувачів, покращення рівня обслуговування, загальної якості та ефективності роботи ресурсів компанії. <br /><br />
                компанія може здійснювати обмін персональними даними із афілійованими, материнськими, дочірніми особами компанії, або на запит власників компанії. В такому випадку персональні дані будуть використовуватись ними виключно з метою та в порядку визначеному даним положенням. <br /><br />
                інформування користувача про випадки передачі його персональних даних, описаних в даному розділі, залишається на розсуд компанії.<br /><br />
              </span><br />
              <span>
              11. Захист персональних даних користувачів здійснюється в магазтні наступним чином.<br /><br />
              Робочі потужності магазину (призначені для роботи з персональними даними) обладнані системними і програмно-технічними засобами та засобами зв'язку, які запобігають втратам, крадіжкам, несанкціонованому знищенню, викривленню, підробленню, копіюванню інформації і відповідають вимогам міжнародних та національних стандартів.<br /><br />
              Норми технічного захисту інформації відповідають законодавству, актам України, зокрема нормативно-правовим актам Комісії, погодженим з державним органом, що визначений законодавством України, відповідальним за встановлення норм технічного захисту інформації в Україні. <br /><br />
              Відповідальні особи (співробітники допущені до роботи з персональними даними) організовують роботу, пов'язану із захистом персональних даних при їх обробці, відповідно до закону. <br /><br />
              Обов’язки відповідальних осіб які мають доступ до персональних даних, щодо організації роботи, пов'язаної із захистом персональних даних при їх обробці зазначаються у посадовій інструкції.<br /><br />
              Співробітники компанії, які безпосередньо здійснюють обробку та/або мають доступ до персональних даних у зв’язку з виконанням своїх службових (трудових) обов’язків зобов’язані дотримуватись вимог законодавства України в сфері захисту персональних даних та внутрішніх документів, що регулюють діяльність компанії щодо обробки і захисту персональних даних у базах персональних даних.<br /><br />
              Співробітники компанії, що мають доступ до персональних даних, у тому числі, ті що здійснюють їх обробку зобов’язані не допускати розголошення у будь-який спосіб персональних даних, які їм було довірено або які стали відомі у зв'язку з виконанням професійних чи службових або трудових обов'язків. Таке зобов'язання чинне після припинення ними діяльності, пов'язаної з персональними даними, крім випадків, установлених законом.<br /><br />
              Особи, що мають доступ до персональних даних, у тому числі, здійснюють їх обробку у разі порушення ними вимог Закону України «Про захист персональних даних» несуть відповідальність згідно законодавства України.<br /><br />
              Персональні дані не зберігаються довше, ніж це необхідно для мети, для якої такі дані зберігаються, але у будь-якому разі не довше строку зберігання даних, визначеного згодою користувача на обробку цих даних. Компанія дотримується вимог Типового порядку обробки персональних даних, що затверджений наказом Верховної Ради України 08.01.2014 № 1/02-14 в тій мірі, в якій це стосується саме персональних даних користувачів.<br /><br />
              </span><br />
              <span>
              12. Права користувача як суб’єкта персональних даних: <br /><br />
              - знати про місцезнаходження бази персональних даних, яка містить його персональні дані, її призначення та найменування, місцезнаходження та/або місце проживання (перебування) володільця чи розпорядника цієї бази або дати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім випадків, встановлених законом; <br />
              - отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються його персональні дані, що містяться у відповідній базі персональних даних; <br />
              - на доступ до своїх персональних даних, що містяться у відповідній базі персональних даних; <br />
              - отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи зберігаються його персональні дані у відповідній базі персональних даних, а також отримувати зміст його персональних даних, які зберігаються; <br />
              - пред'являти вмотивовану вимогу із запереченням проти обробки своїх персональних даних органами державної влади, органами місцевого самоврядування при здійсненні їхніх повноважень, передбачених законом; <br />
              - пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким володільцем та розпорядником цієї бази, якщо ці дані обробляються незаконно чи є недостовірними; <br />
              - на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи; <br />
              - звертатися з питань захисту своїх прав щодо персональних даних до органів державної влади, органів місцевого самоврядування, до повноважень яких належить здійснення захисту персональних даних; <br />
              - застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних.<br />
              </span><br />
              <span>
              13. Порядок роботи з запитами користувача - суб’єкта персональних даних.<br /><br />
              Суб'єкт персональних даних має право на одержання будь-яких відомостей про себе у будь-якого суб'єкта відносин, пов'язаних з персональними даними, без зазначення мети запиту, крім випадків, установлених законом.<br /><br />
              Доступ суб'єкта персональних даних до даних про себе здійснюється безоплатно.<br /><br />
              Суб’єкт персональних даних подає запит щодо доступу (далі - запит) до персональних даних володільцю бази персональних даних.<br /><br />
              У запиті зазначаються: <br /><br />
              - прізвище, ім'я та по батькові, місце проживання (місце перебування) і реквізити документа, що посвідчує особу суб’єкта персональних даних; <br /><br />
              - інші відомості, що дають змогу ідентифікувати особу суб’єкта персональних даних;<br /><br />
              - відомості про базу персональних даних, стосовно якої подається запит, чи відомості про володільця чи розпорядника цієї бази; <br /><br />
              - перелік персональних даних, що запитуються.<br /><br />
              Строк вивчення запиту на предмет його задоволення не може перевищувати десяти робочих днів з дня його надходження. <br /><br />
              Протягом цього строку компанія (володілець бази персональних даних) доводить до відома суб’єкта персональних даних, що запит буде задоволено або відповідні персональні дані не підлягають наданню, із зазначенням підстави, визначеної у відповідному нормативно-правовому акті. <br /><br />
              Запит задовольняється протягом тридцяти календарних днів з дня його надходження, якщо інше не передбачено законом.<br /><br />
              </span><br />
              <span>
              14. Зміни в дане положення можуть час від часу вноситись на розсуд магазину, без додаткового повідомлення користувачів. Зміни вступають в силу з моменту їх розміщення на ресурсах сайту магазина. У разі незгоди користувача із умовами викладеними в даному положенні, користувач повинен припинити використання ресурсів магазину. Факт продовження використання ресурсів компанії є підтвердженням згоди і прийняття користувачем відповідної редакції положення.<br /><br />
              </span><br />
            </div>
          </div>
          <Footer />
        </div>
    </div>
  );
}

export default PrivacyPolicy;