import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [orderUser, setOrderUser] = useState(null);
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${baseURL}/api/user`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
        } catch (error) {
          console.error('Failed to fetch user data', error);
        }
      }
    };

    fetchUser();
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  const addToWishlist = (product, pageId) => {
    const updatedWishlist = [...wishlist, { ...product, pageId }];
    setWishlist(updatedWishlist);
    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
  };

  const removeFromWishlist = (productId, pageId) => {
    const updatedWishlist = wishlist.filter(item => item.id !== productId || item.pageId !== pageId);
    setWishlist(updatedWishlist);
    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
  };

  const isInWishlist = (productId, pageId) => {
    return wishlist.some(item => item.id === productId && item.pageId === pageId);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        orderUser,
        updateOrderUser: (updatedFields) => {
          setOrderUser((prev) => ({ ...prev, ...updatedFields }));
        },
        wishlist,
        addToWishlist,
        removeFromWishlist,
        isInWishlist,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};