import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateTopSlider = () => {
  const [productPage, setProductPage] = useState('');
  const [pages, setPages] = useState([]);
  const [filteredPages, setFilteredPages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [notification, setNotification] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!baseURL) {
      console.error('REACT_APP_SERVER_BASE_URL не визначено в .env файлі');
      return;
    }
    axios.get(`${baseURL}/api/products`)
      .then(response => {
        setPages(response.data);
        setFilteredPages(response.data);
      })
      .catch(error => console.error('Помилка при завантаженні сторінок продуктів:', error));
  }, []);

  const handleSelectProduct = (productId) => {
    setProductPage(productId);
    setIsDropdownOpen(false);

    axios.get(`${baseURL}/api/products/${productId}`)
    .then(response => {
      const product = response.data;
      const formData = {
        product: product._id,
        image: product.images[0],
        productName: product.title,
        price: product.price,
        oldPrice: product.oldPrice,
        promo: product.promo,
        color: product.color,
      };

      axios.post(`${baseURL}/api/slider-cards`, formData)
        .then(() => {
          showNotification('Картка успішно створена!', 'success');
          setProductPage('');
        })
        .catch(error => {
          console.error('Помилка при створенні картки:', error);
          showNotification('Не вдалося створити картку.', 'error');
        });
    })
    .catch(error => console.error('Помилка при завантаженні продукту:', error));
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm) {
      setFilteredPages(
        pages.filter(page =>
          page.title.toLowerCase().includes(searchTerm)
        )
      );
    } else {
      setFilteredPages(pages);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='create_slider-container'>
      <form>
        <div className='slider_card'>
          <p className='block_title'>Оберіть сторінку продукту</p>

          <div className="custom-select-container" onClick={toggleDropdown}>
            <div className="selected-option">
              {productPage ? pages.find(page => page._id === productPage)?.title : 'Оберіть сторінку продукту'}
            </div>

            {isDropdownOpen && (
              <div className="dropdown">
                <input
                  type="text"
                  placeholder="Пошук сторінки продукту..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="search_input"
                  onClick={(e) => e.stopPropagation()}
                />
                <ul className="dropdown-options">
                  {filteredPages.map(page => (
                    <li
                      key={page._id}
                      onClick={() => handleSelectProduct(page._id)}
                      className="dropdown-option"
                    >
                      {page.title}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </form>

      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default CreateTopSlider;