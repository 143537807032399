import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import icon from '../../icon/icon';
import './panel.css';

import ContentTabs from './ContentTabs';
import OrderDetails from '../order/OrderDetails';
import AllReviews from '../../components/review/AllReviews';
import VisitChart from './User/VisitChart';
import UserContainer from './User/UserContainer';
import SEOManager from './SeoContent/SeoManager';
import AnalyticsContent from './AnalyticsContent/AnalyticsContent';
import UpdateCredentials from './UpdateCredentials';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const TabPanel = ({ onLogout }) => {
  const [activeTab, setActiveTab] = useState('updown');
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const [newCommentsCount, setNewCommentsCount] = useState(0);
  const [lastOrderId, setLastOrderId] = useState(null);

  const navigate = useNavigate();

  const fetchNewOrders = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/orders/new-orders`);
      const newOrders = response.data;
  
      setNewOrdersCount(newOrders.length);
      setLastOrderId(newOrders.length > 0 ? newOrders[newOrders.length - 1]._id : null);
    } catch (error) {
      console.error('Помилка отримання нових замовлень:', error);
    }
  };

  const fetchNewComments = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/comments/new-comments`);
      setNewCommentsCount(response.data.length);
    } catch (error) {
      console.error('Помилка отримання нових коментарів:', error);
    }
  };

  useEffect(() => {
    fetchNewOrders();
    fetchNewComments();
    const interval = setInterval(() => {
      fetchNewOrders();
      fetchNewComments();
    }, 5000);
    return () => clearInterval(interval);
  }, [lastOrderId]);

  const handleTabClick = async (tabId) => {
    setActiveTab(tabId);
  
    if (tabId === 'folder') {
      try {
        await axios.patch(`${baseURL}/api/orders/mark-orders-read`);
        setNewOrdersCount(0);
      } catch (error) {
        console.error('Помилка очищення нових замовлень:', error);
      }
    }

    if (tabId === 'review') {
      try {
        await axios.patch(`${baseURL}/api/comments/mark-comments-read`);
        setNewCommentsCount(0);
      } catch (error) {
        console.error('Помилка очищення нових коментарів:', error);
      }
    }
  };

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const tabs = [
    { id: 'updown', label: 'Наповнення', img: icon.UpDownIcon, imgActive: icon.UpDownIconW, content: <ContentTabs /> },
    {
      id: 'folder',
      label: (
        <span>
          Замовлення
          {newOrdersCount > 0 && (
            <span className="new-order-count-label">+{newOrdersCount}</span>
          )}
        </span>
      ),
      img: icon.FolderIcon,
      imgActive: icon.FolderIconW,
      content: <OrderDetails />,
    },
    {
      id: 'review',
      label: (
        <span>
          Коментарі
          {newCommentsCount > 0 && (
            <span className="new-comment-count-label">+{newCommentsCount}</span>
          )}
        </span>
      ),
      img: icon.Chat,
      imgActive: icon.ChatW,
      content: <AllReviews />,
    },
    { id: 'calendar', label: 'Відвідування', img: icon.CalendarIcon, imgActive: icon.CalendarIconW, content: <VisitChart /> },
    { id: 'analytics', label: 'Аналітика', img: icon.AnalyticsIcon, imgActive: icon.AnalyticsIconW, content: <AnalyticsContent /> },
    { id: 'seo', label: 'SEO', img: icon.SeoIcon, imgActive: icon.SeoIconW, content: <SEOManager /> },
    { id: 'settings', label: 'Паролі', img: icon.SettingsIcon, imgActive: icon.SettingsIconW, content: <UpdateCredentials /> },
  ];

  return (
    <div className="tab_container">
      <div className="tab_buttons">
        <div className="inner_button">
          <p className="btn_item-title">Панель керування</p>
          <div className="inner_col-btn">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`tab_button ${activeTab === tab.id ? 'active' : ''}`}
                onClick={() => handleTabClick(tab.id)}
              >
                <span className="tab_label">{tab.label}</span>
                <img
                  src={activeTab === tab.id ? tab.imgActive : tab.img}
                  alt={tab.label}
                  className="tab_icon"
                />
              </button>
            ))}
          </div>
          <button className="exit_btn" onClick={handleLogout}>
            Вихід <img src={icon.ExitIcomW} alt="#" />
          </button>
        </div>
      </div>
  
      <div className="tab_content">
        {tabs.find((tab) => tab.id === activeTab)?.content}
      </div>
    </div>
  );
};

export default TabPanel;