import React, { useState } from 'react';
import CreateTopSlider from './CreateSliderOrd';
import DeleteTopSlider from './DeleteSliderOrd';

const TabSliderOrd = () => {
  const [selectedTab, setSelectedTab] = useState('create');

  return (
    <div className="slider-card-manager">
      <div className="inner_card-manager">
        <p className='slide_title'>Слайдер Зараз купують</p>
        <div className="tabs_line">
          <button className={`tab_btn ${selectedTab === 'create' ? 'active' : ''}`} onClick={() => setSelectedTab('create')}>Створити картку</button>
          <button className={`tab_btn ${selectedTab === 'delete' ? 'active' : ''}`} onClick={() => setSelectedTab('delete')}>Видалити картку</button>
        </div>
        
        <div className="content_slider-create">
          {selectedTab === 'create' && <CreateTopSlider />}
          {selectedTab === 'delete' && <DeleteTopSlider />}
        </div>
      </div>
    </div>
  );
};

export default TabSliderOrd;