import HomeIcon from './icon-dark/home-icon.svg';
import AnalyticsIcon from './icon-dark/analytics-icon.svg';
import BagIcon from './icon-dark/bag-icon.svg';
import CalendarIcon from './icon-dark/calendar-icon.svg';
import FolderIcon from './icon-dark/folder-icon.svg';
import PersonIcon from './icon-dark/person-icon.svg';
import SearchIcon from './icon-dark/search-icon.svg';
import SeoIcon from './icon-dark/seo-icon.svg';
import SettingsIcon from './icon-dark/settings-icon.svg';
import UpDownIcon from './icon-dark/up-down-icon.svg';
import Chat from './icon-dark/chat.svg';

import HomeIconW from './icon-white/home-icon-w.svg';
import AnalyticsIconW from './icon-white/analytics-icon-w.svg';
import BagIconW from './icon-white/bag-icon-w.svg';
import CalendarIconW from './icon-white/calendar-icon-w.svg';
import FolderIconW from './icon-white/folder-icon-w.svg';
import PersonIconW from './icon-white/person-icon-w.svg';
import SearchIconW from './icon-white/search-icon-w.svg';
import SeoIconW from './icon-white/seo-icon-w.svg';
import SettingsIconW from './icon-white/settings-icon-w.svg';
import UpDownIconW from './icon-white/up-down-icon-w.svg';
import ExitIcomW from './icon-white/exit-icon-w.svg';
import ChatW from './icon-white/chat-w.svg';

import Facebook from './icon-footer/facebook.svg';
import Instagram from './icon-footer/instagram.svg';
import Phone from './icon-footer/phone.svg';
import Telegram from './icon-footer/telegram.svg';
import TikTok from './icon-footer/tiktok.svg';

import EyeIcon from './icon-dark/eye.svg';

const icon = {
  HomeIcon,
  AnalyticsIcon,
  BagIcon,
  CalendarIcon,
  FolderIcon,
  PersonIcon,
  SearchIcon,
  SeoIcon,
  SettingsIcon,
  UpDownIcon,
  HomeIconW,
  AnalyticsIconW,
  BagIconW,
  CalendarIconW,
  FolderIconW,
  PersonIconW,
  SearchIconW,
  SeoIconW,
  SettingsIconW,
  UpDownIconW,
  ExitIcomW,
  Facebook,
  Instagram,
  Phone,
  Telegram,
  TikTok,
  EyeIcon,
  Chat,
  ChatW
};

export default icon;