import React, { useEffect } from 'react';
import { useCart } from '../../CartContext';
import { useNavigate } from 'react-router-dom';
import icon from '../../icon/icon';
import iconSlider from '../../icon/other-icon/other-icon';
import '../nav/nav.css';

function Bag() {
  const { cartItems, removeFromCart, updateQuantity, totalItems, totalPrice, isCartOpen, toggleCart } = useCart();
  const navigate = useNavigate();

  const handleIncrement = (id, quantity) => {
    updateQuantity(id, quantity + 1);
  };

  const handleDecrement = (id, quantity) => {
    updateQuantity(id, Math.max(1, quantity - 1));
  };

  const handleCheckout = () => {
    toggleCart();
    navigate('/checkout', { state: { fromCart: true } });
  };

  return (
    <>
      <button className="bag_btn" onClick={toggleCart}>
        <img src={icon.BagIcon} alt="Bag" />
        {cartItems.length > 0 && <span className="bag-indicator"></span>}
      </button>
      {isCartOpen && (
        <div className="sidebar-overlay">
          <div className="sidebar-content">
            <div className="inner_side-bar">
              <div className="top_bar">
                <p className="bar_title">Кошик</p>
                <button className="close-btn" onClick={toggleCart}>
                  <img src={iconSlider.CloseIcon} alt="Закрити" />
                </button>
              </div>
              <div className="order_item-all">
                <div className="inner_order-list">
                {cartItems.map((item) => (
                  <div key={item.cartId} className="order_list-item">
                    <div className="image_count-title">
                      <img className="order_image-bag" src={item.image} alt="" />
                      <p className="order_title-bag">
                        <a 
                          href="#" 
                          className="product-link" 
                          onClick={() => {
                            toggleCart();
                            navigate(item.url, { state: { fromCart: true } });
                          }}
                        >
                          {item.title}
                        </a>
                      </p>
                      <button className="delete_order-btn" onClick={() => removeFromCart(item.cartId)}>
                        <img src={iconSlider.DeleteIcon} alt="Видалити" />
                      </button>
                    </div>
                    <div className="order_price-count">
                      <div className="count_item">
                        <button className="minus_btn" onClick={() => handleDecrement(item.cartId, item.quantity)}>
                          <img src={iconSlider.Minus} alt="Мінус" />
                        </button>
                        <span>{item.quantity}</span>
                        <button className="plus_btn" onClick={() => handleIncrement(item.cartId, item.quantity)}>
                          <img src={iconSlider.Plus} alt="Плюс" />
                        </button>
                      </div>
                      <p className="order_price-bag">{(item.price * item.quantity).toLocaleString()} грн</p>
                    </div>
                  </div>
                ))}
                </div>
              </div>
              <div className="bottom_bar">
                <div className="all_price-item">
                  <p className="all_price">Кількість замовлень: <span>{totalItems}</span></p>
                  <p className="all_count">Загальна ціна: <span>{totalPrice.toLocaleString()}</span> грн.</p>
                </div>
                <button className="pay_btn" onClick={handleCheckout}>Оформити замовлення</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Bag;