import React, { useState, useEffect } from "react";
import axios from "axios";
import iconSlider from '../../../icon/other-icon/other-icon'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function CreateFilter() {
  const [filters, setFilters] = useState([]);
  const [newFilter, setNewFilter] = useState({ name: "", options: [] });
  const [existingFilterOptions, setExistingFilterOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [existingFilters, setExistingFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/catalog-cards`);
        setCategories(response.data);
      } catch (error) {
        console.error("Помилка при завантаженні категорій:", error);
        showNotification("Не вдалося завантажити категорії.", "error");
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchExistingFilters = async () => {
      if (!selectedCategory) return;
      try {
        const response = await axios.get(`${baseURL}/api/filters/${selectedCategory}`);
        setExistingFilters(response.data);
      } catch (error) {
        console.error("Помилка при завантаженні існуючих фільтрів:", error);
        showNotification("Не вдалося завантажити фільтри.", "error");
      }
    };

    fetchExistingFilters();
  }, [selectedCategory]);

  const addOptionToNewFilter = () => {
    setNewFilter({
      ...newFilter,
      options: [...newFilter.options, { value: "" }],
    });
  };

  const updateOptionInNewFilter = (index, value) => {
    const updatedOptions = [...newFilter.options];
    updatedOptions[index].value = value;
    setNewFilter({ ...newFilter, options: updatedOptions });
  };

  const saveNewFilter = () => {
    if (newFilter.name.trim() && newFilter.options.length > 0) {
      setFilters([...filters, newFilter]);
      setNewFilter({ name: "", options: [] });
      showNotification("Новий фільтр успішно створено.", "success");
    } else {
      showNotification("Будь ласка, заповніть усі поля для фільтру.", "error");
    }
  };

  const addOptionToExistingFilter = async () => {
    if (!selectedFilter || existingFilterOptions.length === 0) {
      showNotification("Виберіть фільтр та додайте хоча б одну опцію.", "error");
      return;
    }

    try {
      const newOptions = existingFilterOptions.map((option) => ({ value: option.value }));

      await axios.patch(`${baseURL}/api/filters/update-options`, {
        filterId: selectedFilter,
        newOptions,
      });

      showNotification("Опції успішно додані!", "success");

      const updatedFilters = existingFilters.map((filter) =>
        filter._id === selectedFilter
          ? { ...filter, options: [...filter.options, ...newOptions] }
          : filter
      );
      setExistingFilters(updatedFilters);
      setExistingFilterOptions([]);
    } catch (error) {
      console.error("Помилка при додаванні опцій:", error);
      showNotification("Не вдалося додати опції до фільтру.", "error");
    }
  };

  const deleteFilter = async (filterId) => {
    try {
      await axios.delete(`${baseURL}/api/filters/${filterId}`);
      setExistingFilters(existingFilters.filter((filter) => filter._id !== filterId));
      showNotification("Фільтр успішно видалено.", "success");
    } catch (error) {
      console.error("Помилка при видаленні фільтру:", error);
      showNotification("Не вдалося видалити фільтр.", "error");
    }
  };

  const handleSaveToDatabase = async () => {
    if (!selectedCategory) {
      showNotification("Будь ласка, оберіть категорію.", "error");
      return;
    }

    try {
      for (const filter of filters) {
        await axios.post(`${baseURL}/api/filters`, {
          category: selectedCategory,
          filter: { name: filter.name, options: filter.options },
        });
      }
      showNotification("Фільтри успішно збережені!", "success");
      setFilters([]);
      window.dispatchEvent(new Event("filtersUpdated"));
    } catch (error) {
      console.error("Помилка при збереженні фільтрів:", error);
      showNotification("Не вдалося зберегти фільтри.", "error");
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const getCategoryName = () => {
    const selectedCategoryObject = categories.find(
      (category) => category._id === selectedCategory
    );
    return selectedCategoryObject ? selectedCategoryObject.title : "Не вибрано";
  };

  const deleteFilterOption = async (filterId, optionId) => {
    try {
      await axios.patch(`${baseURL}/api/filters/remove-option`, {
        filterId,
        optionId,
      });
  
      const updatedFilters = existingFilters.map((filter) => {
        if (filter._id === filterId) {
          return {
            ...filter,
            options: filter.options.filter((option) => option._id !== optionId),
          };
        }
        return filter;
      });
  
      setExistingFilters(updatedFilters);
      showNotification("Опцію успішно видалено.", "success");
    } catch (error) {
      console.error("Помилка при видаленні опції:", error);
      showNotification("Не вдалося видалити опцію.", "error");
    }
  };

  return (
    <div className="create_filter-item">
      <div className="create_inner-filter">
        <h3>Фільтри пошуку в підкатегоріях</h3>

        <div className="filter_item-select">
          <label>Підкатегорія для створення фільтрів</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            <option value="">Оберіть підкатегорію</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.title}
              </option>
            ))}
          </select>
        </div>

        <div className="create_filter">
          <h4>Створити новий фільтр</h4>
          <label>Назва групи фільтрів</label>
          <input
            type="text"
            placeholder="Назва (Наприклад: Бренд)"
            value={newFilter.name}
            onChange={(e) => setNewFilter({ ...newFilter, name: e.target.value })}
          />
          {newFilter.options.map((option, index) => (
            <div className="filter_input-item" key={index}>
              <input
                type="text"
                placeholder={`Фільтр (Наприклад: Apple) ${index + 1}`}
                value={option.value}
                onChange={(e) => updateOptionInNewFilter(index, e.target.value)}
              />
            </div>
          ))}
          <div className="filter_item-btn">
            <button onClick={addOptionToNewFilter}>Додати опцію</button>
            <button onClick={saveNewFilter}>Зберегти</button>
          </div>
        </div>

        <div className="filter_add">
          <h4>Створені фільтри</h4>
          {filters.map((filter, index) => (
            <div key={index}>
              <p>Назва групи "{filter.name}"</p>
              <ul>
                {filter.options.map((option, idx) => (
                  <li key={idx}>Назва фільтру "{option.value}"</li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <button className="filter_add-btn" onClick={handleSaveToDatabase}>Додати на сторінку</button>

        <div className="existing_filter-item">
          <h4>Існуючі фільтри для підкатегорії "{getCategoryName()}"</h4>
          {existingFilters.length > 0 ? (
            existingFilters.map((filter) => (
              <div key={filter._id}>
                <p>Назва групи "{filter.name}"</p>
                <ul>
                  {filter.options.map((option) => (
                    <li key={option._id}>
                      Назва фільтру "{option.value}"
                      <button className="delete_option" onClick={() => deleteFilterOption(filter._id, option._id)}><img src={iconSlider.DeleteIcon} alt="#" /></button>
                    </li>
                  ))}
                </ul>
                <button className="delete_filter-item" onClick={() => deleteFilter(filter._id)}>Видалити всю групу</button>
              </div>
            ))
          ) : (
            <p>Немає існуючих фільтрів для вибраної підкатегорії.</p>
          )}
        </div>

        {notification && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateFilter;