import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import NavPanel from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer'
import '../catalog/catalog.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function CategoriesPage() {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchCategoryPage = async () => {
      try {
        const response = await fetch(`${baseURL}/api/categories/${id}`);
        const data = await response.json();
        if (response.ok) {
          setTitle(data.title);
        } else {
          console.error('Error:', data.message);
        }
      } catch (error) {
        console.error('Error fetching category page:', error);
      }
    };

    fetchCategoryPage();
  }, [id]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch(`${baseURL}/api/catalog-cards?categoryId=${id}`);
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error('Помилка при завантаженні карток:', error);
      }
    };
  
    fetchCards();
  }, [id]);

  return (
    <div className="main-container">
      <NavPanel />
      <div className="container">
        <div className="podcategories_container">
          <div className="inner_catalog">
            <h2 className="categories_title">{title}</h2>
            <div className="categories_grid">
              {cards.map((card) => (
                <Link key={card._id} className="pod-categories_card" to={`/katehoriya/${card._id}`}>
                  <img src={card.imageUrl} alt={card.title} />
                  <p>{card.title}</p>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CategoriesPage;
