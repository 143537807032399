import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from '../../CartContext';
import { UserContext } from '../../userContext';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import NavPanel from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer';
import Review from '../../components/review/review';
import './product.css';
import iconSlider from '../../icon/other-icon/other-icon';
import Tracker from '../panel/AnalyticsContent/Tracker';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function ProductPage() {
  const { id } = useParams();
  const { addToCart, toggleCart, cartItems } = useCart();
  const [product, setProduct] = useState(null);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [generatedCode, setGeneratedCode] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [notification, setNotification] = useState(null);

  const { wishlist, addToWishlist, removeFromWishlist, isInWishlist, user } = useContext(UserContext);

  const handleWishlistClick = () => {
    if (!user) {
      showNotification('Будь ласка, авторизуйтесь в кабінеті', 'error');
      return;
    }

    const pageId = id;

    if (isInWishlist(product.id, pageId)) {
      removeFromWishlist(product.id, pageId);
    } else {
      const productData = {
        id: product.id,
        name: product.title,
        image: product.images[currentSlide],
        url: `/prodykt/${id}`,
      };
      addToWishlist(productData, pageId);
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 2000);
  };

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  useEffect(() => {
    const isProductInCart = cartItems.some(item => item.id === product?.id && item.cartId === id);
    setIsAddedToCart(isProductInCart);
  }, [cartItems, product?.id, id]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await axios.get(`${baseURL}/api/products/${id}`);
        setProduct(res.data);
        setGeneratedCode(() => {
          const randomCode = Math.floor(1000000 + Math.random() * 9000000);
          return randomCode.toString();
        });

        const storedViewedProducts = JSON.parse(localStorage.getItem('viewedProducts')) || [];
        const newViewedProduct = {
          id: res.data._id,
          title: res.data.title,
          price: res.data.price,
          oldPrice: res.data.oldPrice,
          image: res.data.images[0],
          promo: res.data.promo,
        };
        const updatedViewedProducts = [newViewedProduct, ...storedViewedProducts];
        localStorage.setItem('viewedProducts', JSON.stringify(updatedViewedProducts));

      } catch (error) {
        console.error('Помилка завантаження продукту:', error);
      }
    };
    fetchProduct();
  }, [id]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % (product?.images?.length || 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + (product?.images?.length || 1)) % (product?.images?.length || 1));
  };

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAddToCart = () => {
    if (product && !isAddedToCart) {
      addToCart({
        id: product.id,
        title: product.title,
        price: product.price,
        image: product.images[currentSlide],
        cartId: id,
        url: `/prodykt/${id}`,
      });
      setIsAddedToCart(true);
      toggleCart();
    }
  };

  return (
    <Tracker productId={id}>
      <div className="main-container">
        <NavPanel />
        <Helmet>
          <title>{product ? product.seoTitle || product.title : 'Завантаження...'}</title>
          <meta name="description" content={product ? product.seoDescription || 'Опис продукту' : 'Завантаження...'} />
          <meta 
            name="keywords" 
            content={Array.isArray(product?.seoKeywords) ? product.seoKeywords.join(', ') : 'Продукти, Купити'} 
          />
          <meta name="robots" content="index, follow"></meta>
          {product && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Product",
                "name": product.title,
                "image": product.images?.map(image => `${image}`),
                "description": product.description,
                "brand": product.brand || "Unknown",
                "offers": {
                  "@type": "Offer",
                  "priceCurrency": product.currency || "UAH",
                  "price": product.price,
                  "availability": product.inStock
                    ? "https://schema.org/InStock"
                    : "https://schema.org/OutOfStock",
                },
              })}
            </script>
          )}
        </Helmet>
        <div className="container">
          <div className="product_container">
            <div className="inner_product">
              <div className="top_product">
                <div className="stock_code">
                  <div className="stock_promo-item">
                    {product?.promo && product.promo !== 'Без промо' && (
                      <p
                        className="promo"
                        style={{
                          backgroundColor:
                            product.promo === 'Хіт продажу' || product.promo === 'Топ продажу'
                              ? 'orange'
                              : product.promo === 'Новинка'
                              ? 'green'
                              : 'red',
                        }}
                      >
                        {product.promo}
                      </p>
                    )}
                  </div>
                  <div className="stock_code-item">
                    <p className="stock">В наявності</p>
                    {/* <p className="code">Код:<span> {generatedCode}</span></p> */}
                  </div>
                </div>
                <div className="slider-container">
                  <button className="arrow left" onClick={prevSlide}>
                    <img src={iconSlider.ArrowLeft} alt="Назад" />
                  </button>
                  {product && product.images && product.images.length > 0 ? (
                    <img
                      src={product.images[currentSlide]}
                      alt={`Slide ${currentSlide + 1}`}
                      className="slide-image"
                    />
                  ) : (
                    <p>Зображення відсутні</p>
                  )}
                  <button className="arrow right" onClick={nextSlide}>
                    <img src={iconSlider.ArrowRight} alt="Вперед" />
                  </button>
                </div>
              </div>
              <div className="bottom_product">
                <p className="prodct_title">{product?.title}</p>
                <div className="color_title-item">
                  <p className='color_title'>Колір</p>
                  <div className="color_item">
                    <span className='color' style={{ backgroundColor: product?.color || 'transparent' }}></span>
                  </div>
                </div>
                <div className="order_price">
                  <div className="price_current-old">
                    {product?.promo === 'Акція' && product?.oldPrice && (
                      <p className="old_price">{formatPrice(product.oldPrice)} грн</p>
                    )}
                    <p
                      className="product_price"
                      style={{
                        color: product?.promo === 'Акція' ? 'red' : 'inherit',
                      }}
                    >
                      {formatPrice(product?.price)} грн
                    </p>
                  </div>
                  <div className="order_wish">
                    <button 
                      className={`add-order_btn ${isAddedToCart ? 'disabled' : ''}`} 
                      onClick={handleAddToCart }
                      disabled={isAddedToCart}
                      style={{ opacity: isAddedToCart ? 0.5 : 1 }}
                    >
                      {isAddedToCart ? 'У кошику' : 'Купити'}
                      <img src={iconSlider.PlusIcon} alt="Купити" />
                    </button>
                    <button
                      onClick={handleWishlistClick}
                      className={`add-order_wish ${isInWishlist(product?.id, id) ? 'disabled' : ''}`}
                      style={{ opacity: isInWishlist(product?.id, id) ? 0.5 : 1 }}
                    >
                      <img src={iconSlider.WishIcon} alt="Бажане" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="descr_char-container">
                <p className="descr_title">Опис продукту:</p>
                <div className={`descr_text-wrapper ${isExpanded ? 'expanded' : ''}`}>
                  <p className="descr_text">{product?.description}</p>
                  {!isExpanded && <div className="overlay"></div>}
                </div>

                <button className="toggle-button" onClick={toggleDescription}>
                  {isExpanded ? 'Приховати' : 'Читати все'}
                </button>
                <div className="char_descr">
                  <p className="char_descr-title">Характеристики:</p>
                  <div className="char_list">
                    {product?.characteristics?.map((char, index) => (
                      <div key={index} className="char_item">
                        <p className="char_name">{char.name}:</p>
                        <p className="char_value">{char.value}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Review productId={id} />
          {notification && <div className={`notification ${notification.type}`}>{notification.message}</div>}
          <Footer />
        </div>
      </div>
    </Tracker>
  );
}

export default ProductPage;