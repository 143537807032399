import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../src/reset.css';
import '../src/index.css';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from './CartContext';
import { UserProvider } from './userContext';
import { OrderProvider } from './OrderContext';
import { SocketProvider } from './SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
   <SocketProvider>
    <UserProvider>
        <CartProvider>
          <OrderProvider>
            <App />
          </OrderProvider>
        </CartProvider>
      </UserProvider>
   </SocketProvider>
  </BrowserRouter>
);