import React from 'react';
import NavPanel from '../../components/nav/Nav'
import CartHero from '../../components/bag/CartHero'
import Footer from '../../components/footer/Footer'
import MenuCategories from '../catalog/MenuCategories'
import ViewedProducts from '../cabinet/ViewProduct';
import Slider from '../slider/SliderLine';
import TopSlider from '../slider/TopSlider'
import IntSlider from '../slider/IntSlider'
import RecSlider from '../slider/RecSlider'
import BetSlider from '../slider/BetterSlider'
import OrdSlider from '../slider/OrderSlider'
import HeroSlider from '../panel/SlideHero/HeroSlider'

import './home.css'

function HomePage() {
  return (
    <div className='main-container'>
        <NavPanel />
        <div className="container">
          <div className="slider_catalog-container">
            <div className="card-catalog">
              <CartHero />
              <div className="slider_menu">
                <MenuCategories />
                <HeroSlider />
              </div>
            </div>
          </div>
          <Slider />
          <ViewedProducts />
          <TopSlider />
          <BetSlider />
          <IntSlider />
          <OrdSlider />
          <RecSlider />
          <Footer />
        </div>
    </div>
  );
}

export default HomePage;