import React, { useState } from 'react';
import icon from '../../icon/icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const UpdateCredentials = () => {
  const [newUsername, setNewUsername] = useState('');
  const [confirmUsername, setConfirmUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newUsername !== confirmUsername) {
      showNotification('Логіни не співпадають', 'error');
      return;
    }
    if (newPassword !== confirmPassword) {
      showNotification('Паролі не співпадають', 'error');
      return;
    }

    try {
      const response = await fetch(`${baseURL}/api/admin/update-credentials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: newUsername, password: newPassword }),
      });

      if (response.ok) {
        showNotification('Логін і пароль успішно оновлено', 'success');
        setNewUsername('');
        setConfirmUsername('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        const data = await response.json();
        showNotification(data.message || 'Помилка оновлення даних', 'error');
      }
    } catch (error) {
      console.error('Помилка запиту:', error);
      showNotification('Помилка з’єднання з сервером', 'error');
    }
  };

  return (
    <div className="update-credentials-container">
      <div className="credentials_title">
        <div className="inner_credentials-title">
          <h2>Оновлення логіна і пароля</h2>
        </div>
      </div>
      <div className="update-credentials_form">
        <div className="inner-update-credentials">
          <form onSubmit={handleSubmit}>
            <div className="form_item-col">
              <label className="label_title-input">Новий логін:</label>
              <input
                className="input_col"
                type="text"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required
              />
            </div>
            <div className="form_item-col">
              <label className="label_title-input">Повторити логін:</label>
              <input
                className="input_col"
                type="text"
                value={confirmUsername}
                onChange={(e) => setConfirmUsername(e.target.value)}
                required
              />
            </div>
            <div className="form_item-col">
              <label className="label_title-input">Новий пароль:</label>
              <div className="pass_btn-form">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword((prev) => !prev)}
                  aria-label={showNewPassword ? 'Сховати пароль' : 'Показати пароль'}
                >
                  <img
                    src={showNewPassword ? icon.EyeIcon : icon.EyeIcon}
                    alt={showNewPassword ? 'Сховати пароль' : 'Показати пароль'}
                    style={{ width: '20px', height: '20px' }}
                  />
                </button>
              </div>
            </div>
            <div className="form_item-col">
              <label className="label_title-input">Повторити пароль:</label>
              <div className="pass_btn-form">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  aria-label={showConfirmPassword ? 'Сховати пароль' : 'Показати пароль'}
                >
                  <img
                    src={showConfirmPassword ? icon.EyeIcon : icon.EyeIcon} // Використовуємо EyeIcon для обох варіантів
                    alt={showConfirmPassword ? 'Сховати пароль' : 'Показати пароль'}
                    style={{ width: '20px', height: '20px' }}
                  />
                </button>
              </div>
            </div>
            <button className="pass_btn-credentials" type="submit">
              Оновити
            </button>
          </form>
        </div>
      </div>
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default UpdateCredentials;