import React, { useState, useEffect } from "react";
import axios from "axios";
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateSlideForm = () => {
  const [image, setImage] = useState(null);
  const [imageDetails, setImageDetails] = useState("");
  const [selectedPage, setSelectedPage] = useState("");
  const [categories, setCategories] = useState([]);
  const [slides, setSlides] = useState([]);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${baseURL}/api/catalog-cards`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => console.error("Error fetching categories:", error));

    axios.get(`${baseURL}/api/hero-slides`)
      .then(response => {
        setSlides(response.data);
      })
      .catch(error => console.error("Error fetching slides:", error));
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageDetails(`${file.name} (${file.type})`);
    }
  };

  const uploadImageToCloudinary = async () => {
    if (!image) return null;

    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', 'ml_default');

    try {
      const res = await axios.post('https://api.cloudinary.com/v1_1/dca5poo9m/image/upload', formData);
      return res.data.secure_url;
    } catch (error) {
      console.error('Помилка завантаження зображення:', error);
      showNotification('Помилка завантаження зображення.', 'error');
      return null;
    }
  };

  const handleCreateSlide = async (e) => {
    e.preventDefault();
  
    if (!image || !selectedPage) {
      showNotification("Будь ласка, заповніть всі поля.", 'error');
      return;
    }
  
    setLoading(true);
  
    const imageUrl = await uploadImageToCloudinary();
    if (!imageUrl) {
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(`${baseURL}/api/hero-slides`, {
        url: imageUrl,
        categoryId: selectedPage,
      });
  
      setSlides(prevSlides => [...prevSlides, response.data]);
      showNotification("Слайд успішно створений!", 'success');
  
      setImage(null);
      setImageDetails('');
      setSelectedPage("");
    } catch (error) {
      console.error("Error creating slide:", error);
      showNotification("Помилка при створенні слайду.", 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSlide = (id) => {
    axios.delete(`${baseURL}/api/hero-slides/${id}`)
      .then(() => {
        setSlides(prevSlides => prevSlides.filter(slide => slide._id !== id));
        showNotification("Слайд успішно видалено.", 'success');
      })
      .catch(error => {
        console.error("Error deleting slide:", error);
        showNotification("Помилка при видаленні слайду.", 'error');
      });
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className="create_slider-hero__form">
      <div className="inner_slider-form">
        {notification && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        <form onSubmit={handleCreateSlide}>
          <p className='create_title'>Слайдер головної сторінки</p>
          <div className='upload-item'>
            <input
              type="file"
              id="file-input"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              required
            />
            <label htmlFor="file-input" className="custom-upload">
              <img src={iconSlider.DwnIcon} alt="Завантажити" />
              <span>{imageDetails || 'Завантажити картинку'}</span>
            </label>
          </div>
          <div className='edit_card'>
            <p className='select_title'>Оберіть сторінкy підкатегорії</p>
            <select
              id="page"
              value={selectedPage}
              onChange={(e) => setSelectedPage(e.target.value)}
              required
            >
              <option value="" disabled>Оберіть сторінку підкатегорії</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <button className="create_slide-btn" type="submit" disabled={loading}>
            {loading ? "Завантаження..." : "Створити слайд"}
          </button>
        </form>
        <h3 className='create_title'>Створені слайди</h3>
        <div className="slides_list">
          {slides.length > 0 ? (
            slides.map(slide => (
              <div key={slide._id} className="slide_item">
                <img src={slide.url} alt={`Slide ${slide._id}`} style={{ width: '100px', height: 'auto', borderRadius: '5px' }} />
                <button className='delete-slide_btn' onClick={() => handleDeleteSlide(slide._id)}>
                  <img src={iconSlider.DeleteIcon} alt="Видалити" />
                </button>
              </div>
            ))
          ) : (
            <p>Слайдів немає.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateSlideForm;