import React, { useState, useEffect } from 'react';
import { uk } from 'date-fns/locale';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay, addMonths, subMonths } from 'date-fns';
import iconSlider from '../../icon/other-icon/other-icon'

const CustomDatePicker = ({ selectedDate, onChange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentDay, setCurrentDay] = useState(new Date());
  
  useEffect(() => {
    if (!selectedDate) {
      onChange(currentDay);
    }
  }, [selectedDate, currentDay, onChange]);

  const handleDateClick = (date) => {
    onChange(date);
  };

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const startOfMonthDate = startOfMonth(currentMonth);
  const endOfMonthDate = endOfMonth(currentMonth);
  const daysInMonth = eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate });

  return (
    <div className="custom-datepicker">
      <div className="inner-data">
        <div className="datepicker-header">
          <button onClick={handlePrevMonth}><img src={iconSlider.ArrowLeft} alt="Попередній місяць" /></button>
          <span>{format(currentMonth, 'dd.MM.yyyy', { locale: uk })}</span>
          <button onClick={handleNextMonth}><img src={iconSlider.ArrowRight} alt="Наступний місяць" /></button>
        </div>
        <div className="datepicker-body">
          <div className="datepicker-days-of-week"></div>
          <div className="datepicker-days">
            {daysInMonth.map((day) => (
              <div
                key={day}
                className={`datepicker-day 
                  ${isSameDay(day, selectedDate) ? 'selected' : ''} 
                  ${isSameDay(day, currentDay) ? 'today' : ''}`}
                onClick={() => handleDateClick(day)}
              >
                {format(day, 'd')}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;