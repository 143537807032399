import React, { useEffect, useState } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function Tracker({ productId, children }) {
  const [startTime, setStartTime] = useState(Date.now());
  const [city, setCity] = useState(null);
  const [cityLoaded, setCityLoaded] = useState(false);

  useEffect(() => {
    const getCity = async () => {
      try {
        const ipToken = process.env.REACT_APP_IP_TOKEN;
        const response = await axios.get(`https://ipinfo.io/json?token=${ipToken}`);
        const cityName = response.data.city || 'Невідоме місто';
        setCity(cityName);
        setCityLoaded(true);
      } catch (error) {
        console.error('Error fetching city:', error);
        setCity('Невідоме місто');
        setCityLoaded(true);
      }
    };

    getCity();
  }, []);

  const endSession = async () => {
    if (!cityLoaded) return;

    const timeSpent = Math.floor((Date.now() - startTime) / 1000);

    try {
      await axios.post(`${baseURL}/api/analytics`, {
        productId,
        timeOnPage: timeSpent,
        city,
      });
    } catch (error) {
      console.error('Error sending analytics:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', endSession);

    return () => {
      window.removeEventListener('beforeunload', endSession);
      endSession();
    };
  }, [startTime, cityLoaded, city, productId]);

  return (
    <>
      {React.cloneElement(children)}
    </>
  );
}

export default Tracker;
