import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import NavPanel from '../nav/Nav';
import Footer from '../footer/Footer'
import { useCart } from '../../CartContext';
import iconSlider from '../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function SearchResultsPage() {
  const location = useLocation();
  const query = location.state?.query || '';
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { addToCart, toggleCart } = useCart();

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const getReviewCountText = (count) => {
    if (count % 100 >= 11 && count % 100 <= 19) return `${count} відгуків`;
    const lastDigit = count % 10;
    if (lastDigit === 1) return `${count} відгук`;
    if (lastDigit >= 2 && lastDigit <= 4) return `${count} відгуки`;
    return `${count} відгуків`;
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/products`);
        setProducts(response.data);
      } catch (error) {
        console.error('Помилка при завантаженні продуктів:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const normalizedQuery = query.toLowerCase();
    const results = products.filter((product) =>
      product.title.toLowerCase().includes(normalizedQuery)
    );
    setFilteredProducts(results);
  }, [products, query]);

  const getProductCountText = (count) => {
    if (count % 100 >= 11 && count % 100 <= 19) return 'товарів';
    const lastDigit = count % 10;
    if (lastDigit === 1) return 'товар';
    if (lastDigit >= 2 && lastDigit <= 4) return 'товари';
    return 'товарів';
  };

  return (
    <div className="main-container">
      <NavPanel />
      <div className="container">
        <div className="search_results_container">
          <div className="inner-search_results">
            <h2>Результати пошуку</h2>
            <p className="results_count">
              {`Знайдено ${filteredProducts.length} ${getProductCountText(filteredProducts.length)}`}
            </p>
            <div className="categories_grid-pod">
                {filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                    <div key={product._id} className="categories_card">
                    <Link to={`/prodykt/${product._id}`} className="product-link">
                        <img src={product.images[0]} alt={product.title} />
                        <p className="card_product-title">{product.title}</p>
                        <p className="stock_card">
                        {product.inStock ? 'В наявності' : 'Немає в наявності'}
                        {product.promo && product.promo !== 'Без промо' && (
                        <div
                            className="promo_label"
                            style={{
                            backgroundColor:
                            product.promo === 'Хіт продажу' || product.promo === 'Топ продажу'
                            ? 'orange'
                            : product.promo === 'Новинка'
                            ? 'green'
                            : 'red',
                            }}
                        >
                            {product.promo}
                        </div>
                        )}
                        </p>
                        <div className="color_item-card">
                        <span className="color" style={{ backgroundColor: product.color || 'transparent' }}></span>
                        </div>
                    </Link>
                    <div className="price_add-item">
                        <div className="price_current-old">
                        {product?.promo === 'Акція' && product?.oldPrice && (
                            <p className="old_price">{formatPrice(product.oldPrice)} грн</p>
                        )}
                        <p
                            className="product_price"
                            style={{
                            color: product?.promo === 'Акція' ? 'red' : 'inherit',
                            }}
                        >
                            {formatPrice(product.price)} грн
                        </p>
                        </div>
                        <button
                        className="add_btn-card"
                        onClick={(e) => {
                            e.stopPropagation();
                            addToCart({
                            id: product.id,
                            title: product.title,
                            price: product.price,
                            image: product.images[0],
                            cartId: product._id,
                            });
                            toggleCart();
                        }}
                        >
                        <img src={iconSlider.CardBag} alt="Швидке замовлення" />
                        </button>
                    </div>
                        <div className="reviews_block">
                            <img src={iconSlider.RevIcon} alt="#" />
                            <p>{getReviewCountText(product.reviewsCount || 0)}</p>
                        </div>
                        <div className="delivery_icon-title">
                            <p>Готовий до відправлення</p>
                            <img src={iconSlider.DelIcon} alt="#" />
                        </div>
                        </div>
                    ))
                    ) : (
                    <p>Нічого не знайдено</p>
                    )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default SearchResultsPage;