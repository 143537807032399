import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useCart } from '../../CartContext';
import { useNavigate } from 'react-router-dom';
import NavPanel from '../../components/nav/Nav';
import Footer from '../../components/footer/Footer'
import { UserContext } from '../../userContext';
import './order.css';

function CheckoutPage() {
  const { cartItems, totalPrice } = useCart();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { orderUser, updateOrderUser } = useContext(UserContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    city: '',
    region: '',
    deliveryMethod: '',
    deliveryAddress: '',
    paymentMethod: '',
    department: '',
  });

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
        city: user.city || '',
        region: user.region || '',
        department: user.departmentNumber || '',
        deliveryMethod: user.deliveryMethod || 'novaPostDepartment',
        paymentMethod: user.paymentMethod || 'cashOnDelivery',
      }));
    }
  }, [user]);

  const TOKEN = process.env.REACT_APP_TELEGRAM_TOKEN;
  const CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;
  const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegionChange = (e) => {
    updateOrderUser({ region: e.target.value });
  };

  const sendOrderToTelegram = async (message) => {
    try {
      await axios.post(URI_API, {
        chat_id: CHAT_ID,
        parse_mode: 'html',
        text: message,
      });
    } catch (error) {
      console.error('Помилка відправки повідомлення до Telegram:', error);
      alert('Виникла помилка під час відправки замовлення. Спробуйте ще раз.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, phone, city, department, deliveryMethod, paymentMethod, region, district, street, buildingNumber } = formData;
    let message = `<b>Нове замовлення!</b>\n\n`;
    message += `<b>Ім'я: </b>${firstName} ${lastName}\n`;
    message += `<b>Телефон: </b>${phone}\n\n`;
    
    if (deliveryMethod === "novaPostDepartment") {
      message += `<b>Спосіб доставки:</b> Нова Пошта - відділення\n`;
      message += `<b>Область:</b> ${region}\n`;
      message += `<b>Місто:</b> ${city}\n`;
      message += `<b>Відділення №:</b> ${department}\n`;
    } else if (deliveryMethod === "novaPostPostamat") {
      message += `<b>Спосіб доставки:</b> Нова Пошта - поштомат\n`;
      message += `<b>Місто:</b> ${city}\n`;
      message += `<b>№ поштомату:</b> ${department}\n`;
    } else if (deliveryMethod === "novaPostCourier") {
      message += `<b>Спосіб доставки:</b> Нова Пошта - кур'єр\n`;
      message += `<b>Область:</b> ${region}\n`;
      message += `<b>Місто:</b> ${city}\n`;
      message += `<b>Район:</b> ${district}\n`;
      message += `<b>Вулиця:</b> ${street}\n`;
      message += `<b>Будинок №:</b> ${buildingNumber}\n\n`;
    } else if (deliveryMethod === "ukrPost") {
      message += `<b>Спосіб доставки:</b> Укр Пошта\n`;
      message += `<b>Місто:</b> ${city}\n`;
      message += `<b>Індекс відділення:</b> ${department}\n`;
    }
  
    message += `<b>Спосіб оплати:</b> ${paymentMethod}\n\n`;
    message += `<b>Деталі замовлення:</b>\n`;
    cartItems.forEach((item, index) => {
      message += `<b>Замовлення ${index + 1}:</b> ${item.title}\n`;
      message += `Кількість: ${item.quantity}, Ціна: ${formatPrice(item.price)} грн\n\n`;
    });
    message += `<b>Загальна сума до сплати:</b> ${formatPrice(totalPrice)} грн\n\n`;
    sendOrderToTelegram(message);
    navigate('/order-confirmation', { state: { formData, cartItems, totalPrice } });
  };
  
  const handleCheckout = () => {
    navigate('/order-confirmation');
  };

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const isNovaPostPostamat = formData.deliveryMethod === 'novaPostPostamat';
  const isNovaPostCourier = formData.deliveryMethod === 'novaPostCourier';
  const isUkrPost = formData.deliveryMethod === 'ukrPost';

  return (
    <div className='main-container'>
      <NavPanel />
      <div className="container">
        <div className="check_container">
              <div className="cart_summary">
                <div className="inner-cart_summary">
                  <h3>Ваше замовлення</h3>
                  {cartItems.map((item, index) => (
                    <div key={`${item.id}-${index}`} className="cart-item">
                      <img src={item.image} alt={item.title} className="cart-item-image" />
                      <div className="title_count-check">
                        <p className='order_check-title'>{item.title}</p>
                        <div className="count_price-check">
                          <p>Кількість: {item.quantity}</p>
                          <p>Ціна: {formatPrice(item.price)} грн</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="checkout_form">
                <form className="inner_form" onSubmit={handleSubmit}>
                  <h2>Оформлення замовлення</h2>
                  <h3>Ваші дані</h3>
                  <div className="user_data-item">
                    <div className="item_data">
                      <span>Ваше ім'я</span>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="Ім'я"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="item_data">
                      <span>Ваше прізвище</span>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="Прізвище"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="item_data">
                      <span>Ваш телефон</span>
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Телефон"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <p className="delivery_title">Спосіб доставки</p>
                  <div className="user_delivery-item">
                    <div className="delivery_item">
                      <span>Поштовий перевізник</span>
                      <select
                        name="deliveryMethod"
                        value={formData.deliveryMethod}
                        onChange={handleInputChange}
                      >
                        <option value="novaPostDepartment">Нова Пошта - відділення</option>
                        <option value="novaPostPostamat">Нова Пошта - поштомат</option>
                        <option value="novaPostCourier">Нова Пошта - кур'єр</option>
                        <option value="ukrPost">Укр Пошта</option>
                      </select>
                    </div>
                    {!isNovaPostCourier && (
                      <div className='delivery_courier'>
                        <div className="delivery_item">
                          <span>Область</span>
                          <input
                            type="text"
                            name="region"
                            placeholder="Область"
                            value={formData.region || ''}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="delivery_item">
                          <span>Місто доставки</span>
                          <input
                            type="text"
                            name="city"
                            placeholder="Місто доставки"
                            value={formData.city}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="delivery_item">
                          <span>{isNovaPostPostamat ? "№ поштомату" : isUkrPost ? "Адреса та індекс відділення" : "№ відділення"}</span>
                          <input
                            type="text"
                            name="department"
                            placeholder={isNovaPostPostamat ? "Номер поштомату" : "Номер відділення"}
                            value={formData.department}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    )}
                    {isNovaPostCourier && (
                      <div className='delivery_courier'>
                        <div className="delivery_item">
                        <span>Область</span>
                        <input
                          type="text"
                          name="region"
                          placeholder="Область"
                          value={orderUser?.region || ''}
                          onChange={handleRegionChange}
                        />
                      </div>
                        <div className="delivery_item">
                          <span>Місто</span>
                          <input
                            type="text"
                            name="city"
                            placeholder="Місто"
                            value={formData.city}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="delivery_item">
                          <span>Район</span>
                          <input
                            type="text"
                            name="district"
                            placeholder="Район"
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="delivery_item">
                          <span>Вулиця</span>
                          <input
                            type="text"
                            name="street"
                            placeholder="Вулиця"
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="delivery_item">
                          <span>Номер під'їзду</span>
                          <input
                            type="text"
                            name="buildingNumber"
                            placeholder="Номер під'їзду"
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="payment_options">
                    <h3>Спосіб оплати</h3>
                    <label>
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="Оплата при отриманні"
                        checked={formData.paymentMethod === 'Оплата при отриманні'}
                        onChange={handleInputChange}
                      />
                      <span>Оплата при отриманні</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="Оплата за реквізитами"
                        checked={formData.paymentMethod === 'Оплата за реквізитами'}
                        onChange={handleInputChange}
                      />
                      <span>Оплата за реквізитами</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="Банківський переказ"
                        checked={formData.paymentMethod === 'Банківський переказ'}
                        onChange={handleInputChange}
                      />
                      <span>Банківський переказ</span>
                    </label>
                  </div>
                  <div className="total_price-btn__item">
                    <p className="total-price">Загальна сума до сплати: {formatPrice(totalPrice)} грн</p>
                    <button type="submit" className="confirm-btn">Підтвердити замовлення</button>
                  </div>
                </form>
              </div>
            </div>
        <Footer /> 
      </div>
    </div>
  );
}

export default CheckoutPage;