import React, { useState, useEffect } from 'react';
import './review.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function Review({ productId }) {
  const [feedback, setFeedback] = useState('');
  const [pros, setPros] = useState('');
  const [cons, setCons] = useState('');
  const [reviews, setReviews] = useState([]);
  const [replyInputs, setReplyInputs] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newReview = { feedback, pros, cons, productId };

    const response = await fetch(`${baseURL}/api/reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newReview),
    });

    if (response.ok) {
      setFeedback('');
      setPros('');
      setCons('');
      fetchReviews();
    }
  };

  const fetchReviews = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/api/reviews?productId=${productId}`);
      const data = await response.json();
      setReviews(data);
    } catch (error) {
      console.error('Помилка завантаження відгуків:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVote = async (reviewId, voteType) => {
    const response = await fetch(`${baseURL}/api/reviews/${reviewId}/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ voteType }),
    });

    if (response.ok) {
      fetchReviews();
    }
  };

  const toggleReplyInput = (reviewId) => {
    setReplyInputs((prev) => ({
      ...prev,
      [reviewId]: prev[reviewId] ? undefined : { reply: '' },
    }));
  };

  const handleReplySubmit = async (e, reviewId) => {
    e.preventDefault();
    const replyText = replyInputs[reviewId]?.reply || '';

    const response = await fetch(`${baseURL}/api/reviews/${reviewId}/reply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ reply: replyText }),
    });

    if (response.ok) {
      fetchReviews();
      setReplyInputs((prev) => ({ ...prev, [reviewId]: undefined }));
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [productId]);

  const formatDateTime = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('uk-UA', options);
  };

  return (
    <div className="review_container">
      <div className="inner_review">
        <p className="rev_title">Відгуки</p>
        <form onSubmit={handleSubmit}>
          <span>Ваш відгук</span>
          <textarea
            placeholder="Текст відгуку"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          />
          <span>Переваги</span>
          <input
            type="text"
            placeholder="Напишіть про переваги"
            value={pros}
            onChange={(e) => setPros(e.target.value)}
            required
          />
          <span>Недоліки</span>
          <input
            type="text"
            placeholder="Напишіть про недоліки"
            value={cons}
            onChange={(e) => setCons(e.target.value)}
            required
          />
          <button type="submit">Надіслати відгук</button>
        </form>
        <div className="reviews_list">
          {reviews.map((review, index) => (
            <div key={index} className="review_item">
              <span className="data_review">Дата публікації: {formatDateTime(review.createdAt)}</span>
              <p className="review_text">{review.feedback}</p>
              <p><strong>Переваги:</strong> {review.pros}</p>
              <p><strong>Недоліки:</strong> {review.cons}</p>
              <div className="button_item-rev">
                <div className="vote_buttons">
                  <button onClick={() => handleVote(review._id, 'like')}>👍 {review.likes || 0}</button>
                  <button onClick={() => handleVote(review._id, 'dislike')}>👎 {review.dislikes || 0}</button>
                </div>
                <button className="update_rev" onClick={() => toggleReplyInput(review._id)}>
                  {replyInputs[review._id] ? 'Скасувати' : 'Відповісти'}
                </button>
              </div>
              {replyInputs[review._id] && (
                <form onSubmit={(e) => handleReplySubmit(e, review._id)}>
                  <textarea
                    placeholder="Напишіть відповідь"
                    value={replyInputs[review._id]?.reply || ''}
                    onChange={(e) =>
                      setReplyInputs((prev) => ({
                        ...prev,
                        [review._id]: { ...prev[review._id], reply: e.target.value },
                      }))
                    }
                    required
                  />
                  <button type="submit">Відповісти</button>
                </form>
              )}
              {review.replies?.length > 0 && (
                <div className="replies_list">
                  {review.replies.map((reply, replyIndex) => (
                    <div key={replyIndex} className="reply_item">
                      <p className="reply_text">{reply}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Review;