import React from 'react';
import NavPanel from '../components/nav/Nav'
import Footer from '../components/footer/Footer'

function DeliveryReturn() {
  return (
    <div className='main-container'>
        <NavPanel />
        <div className="container">
          <div className="user_agreement-container">
            <div className="inner_agreement-container">
              <h1>Повернення товарів</h1>
              <p className="user_agreement-title">Перший варіант. Коли трапилася будь-яка помилка з нашого боку.</p><br /><br />
              <span>
                Ми твердо переконані, що ваше замовлення щоразу має бути ідеальним. Ми щосили намагаємося мінімізувати кількість помилок, постійно покращуємо операційні і технологічні процеси, але, на жаль, не застраховані від помилок, які залежать від людського фактору.<br /><br />
                У разі, якщо ми помилилися - доставили вам неякісний товар або товар із поганим терміном, не додали щось у замовлення або ж помилилися інакше - ми за власний рахунок виправимо нашу помилку (обміняємо товар або повернемо гроші) якнайшвидше. Повідомити про помилку можна написавши на пошту або зателефонувавши у контакт-центр.<br /><br />
                Від наших клієнтів ми просимо тільки одного: якщо ми припустилися помилки з вашим замовленням, будь ласка, повідомте нам про це.<br /><br />
                Нам дуже важливо знати про всі помилки, яких ми припускаємось, для того щоб максимально швидко виправляти їх і робити все можливе для того, щоб не припускатись їх у майбутньому.<br /><br />
              </span><br /><br /><br />

              <p className="user_agreement-title">Другий варіант. Коли помилки з нашого боку не було, а вам просто не підходить доставлений товар.</p><br /><br />
              <span>
                Тут теж все досить просто. Якщо з моменту купівлі до вашого звернення пройшло не більше 2-х місяців, товар не був у користуванні і не втратив товарний вигляд (збереглася оригінальна упаковка, немає пошкоджень і слідів користування), просто зателефонуйте нам. Ми заберемо невідповідний товар і повернемо вам кошти.<br /><br /><br />
                У разі, якщо ж товар був у користуванні, кожен випадок розглядається індивідуально. У таких випадках ми не можемо пообіцяти, що обов'язково зробимо повернення, але обов'язково намагатимемося знайти рішення, яке влаштує всіх.<br /><br /><br />
              </span>
            </div>
          </div>
          <Footer />
        </div>
    </div>
  );
}

export default DeliveryReturn;