import React from 'react';
import TabSliderTop from './CreateSliderTop/TabSliderTop';
import TabRecSlider from './CreateSliderRec/TabRecSlider';
import TabIntSlider from './CreateSliderInt/TabIntSlider';
import TabSliderOrd from './CreateSliderOrd/TabSliderOrd';
import TabSliderBet from './CreateSliderBet/TabSliderBet';
import CreateSlideForm from './SlideHero/CreateHeroSlider';

function SliderTab() {
  return (
    <div className='slider_tab-container'>
        <CreateSlideForm />
        <TabSliderTop />
        <TabRecSlider />
        <TabIntSlider />
        <TabSliderOrd />
        <TabSliderBet />
    </div>
  );
}

export default SliderTab;