import React, { useState, useEffect } from 'react';
import CustomDatePicker from './CustomDatePicker';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function OrderDetails() {
  const [orders, setOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [ordersCount, setOrdersCount] = useState(0);

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const parseOrderDate = (dateString) => {
    const [day, month, yearTime] = dateString.split('.');
    const [year, time] = yearTime.split(', ');
    return new Date(`${year}-${month}-${day}T${time}`);
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/api/orders`);
      const sortedOrders = response.data.sort((a, b) => parseOrderDate(b.date) - parseOrderDate(a.date));
      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders);
    } catch (err) {
      setError('Помилка при завантаженні замовлень.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      const filtered = orders.filter(order => {
        const orderDate = parseOrderDate(order.date);
        return (
          orderDate.getDate() === selectedDate.getDate() &&
          orderDate.getMonth() === selectedDate.getMonth() &&
          orderDate.getFullYear() === selectedDate.getFullYear()
        );
      });
      setFilteredOrders(filtered);
      setOrdersCount(filtered.length);
    } else {
      setFilteredOrders(orders);
      setOrdersCount(orders.length);
    }
  }, [selectedDate, orders]);

  useEffect(() => {
    fetchOrders();
  }, []);

  if (loading) {
    return <p>Завантаження замовлень...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const deliveryMethodOptions = {
    novaPostDepartment: 'Нова Пошта - відділення',
    novaPostPostamat: 'Нова Пошта - поштомат',
    novaPostCourier: 'Нова Пошта - кур\'єр',
    ukrPost: 'Укр Пошта',
  };

  return (
    <div className="order_container">
      <ul>
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order, index) => (
            <li className='order-li' key={index}>
              <div className="inner_order_li">
                <div className="number_data">
                  <p>Замовлення №: {order.orderNumber}</p>
                  <p>Дата: {order.date}</p>
                </div>
                <div className="user_order-data">
                  <h4>Покупець</h4>
                  <div className="user_data-col">
                    <p>{order.user.firstName} {order.user.lastName}</p>
                    <p>{order.user.phone}</p>
                  </div>
                </div>
                <div className="delivery_user-order">
                  <h4>Доставка</h4>
                  <p><b>Місто:</b> {order.user.city}</p>
                  <p><b>Спосіб доставки:</b> {deliveryMethodOptions[order.user.deliveryMethod] || order.user.deliveryMethod}</p>
                  {order.user.department && <p><b>Відділення №:</b> {order.user.department}</p>}
                  {order.user.street && (
                    <>
                      <p><b>Область:</b> {order.user.region}</p>
                      <p><b>Район:</b> {order.user.district}</p>
                      <p><b>Вулиця:</b> {order.user.street}</p>
                      <p><b>Будинок №:</b> {order.user.buildingNumber}</p>
                    </>
                  )}
                </div>
                <div className="delivery_user-pay">
                  <h4>Спосіб оплати</h4>
                  <p>{order.user.paymentMethod}</p>
                </div>
                <div className="order_descr-user">
                  <h4>Деталі замовлення</h4>
                  <ul>
                    {order.items.map((item, itemIndex) => (
                      <li key={itemIndex}>
                        <p><b>Назва:</b> {item.title}</p>
                        <p><b>Кількість:</b> {item.quantity}</p>
                        <p><b>Ціна:</b> {formatPrice(item.price)} грн</p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="order_price-all">
                  <p>Загальна кількість позицій: {order.items.length}</p>
                  <p>Загальна сума: {formatPrice(order.total)} грн</p>
                </div>
              </div>
            </li>
          ))
        ) : (
          <p>Замовлення відсутні.</p>
        )}
      </ul>

      <div className="date-picker-container">
        <CustomDatePicker selectedDate={selectedDate} onChange={handleDateChange} />
        <div className="order-count">
          <div className="inner_order-count">
            <h4>Загальна кількість замовлень:</h4>
            <p>{ordersCount}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;